


import './copy-right.css'
const CopyRightPage = () => {
    return (
        <div className='flex justify-center items-center p-[50px]'>


            <page size="A4">
                <div className="container width_2 mt-5">
                    <h1><b>Sofia Privacy Policy
                    </b></h1>

                    <h5>
                        <p>
                            Sofia "the App" provides product sourcing, product importing, description editing, and order
                            fulfillment services ("the Service") to merchants who use Shopify to power their stores. This Privacy Policy describes how
                            personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
                        </p>

                    </h5></div>
                <div className="container width_2 mt-5">
                    <h2><b>Personal Information the App Collects</b></h2>

                    <p>
                        When you install the App, we are automatically able to access certain types of information from your Shopify account:
                    </p>
                    <div
                        className='px-5'>
                        <ul >
                            <li>
                                <b> Order Information: </b> Information related to orders placed through your Shopify store, including customer name, email address, shipping address, billing address, payment information, and order details.
                            </li>
                            <li>
                                <b>  Product Information:</b> Details of products available in your store, including descriptions, prices, inventory levels, and variants.
                            </li>
                            <li>
                                <b>Store Information:</b>   Store settings and preferences, such as store name, currency, and locale.
                            </li>
                        </ul>
                    </div>
                    <p>
                        Additionally, we collect the following types of personal information independently from Shopify APIs:
                    </p>
                    <div
                        className='px-5'>
                        <ul >
                            <li>
                                <b>  Information about Merchants: </b>
                                Information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information.

                            </li>
                            <li>
                                <b>    Information about Store Visitors:</b> Information about individuals who visit your store, such as their IP address, web browser details, time zone, and information about the cookies installed on their devices.
                            </li>

                        </ul>
                    </div>
                    <p>
                        We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies:
                    </p>
                    <div
                        className='px-5'>
                        <ul >
                            <li>
                                <b> Cookies: </b>
                                Data files that are placed on your device or computer and often include an anonymous unique identifier.
                                For more information about cookies, and how to disable cookies, visit: <a href='http://www.allaboutcookies.org' target='_blank'>http://www.allaboutcookies.org</a>.
                            </li>
                            <li>
                                <b> Log Files:</b>
                                Track actions occurring on the Site and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                            </li>
                            <li>
                                <b>Web Beacons, Tags, and Pixels:</b>
                                Electronic files used to record information about how you browse the Site.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container width_2 mt-5">
                    <h2 id="log-data"><b>How Do We Use Your Personal Information?</b></h2>
                    <p>
                        We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to:

                    </p>
                    <div
                        className='px-5'>
                        <ul >
                            <li>
                                Communicate with you;
                            </li>
                            <li>
                                Optimize or improve the App;  </li>
                            <li>
                                Provide you with information or advertising relating to our products or services.
                            </li>
                        </ul>
                    </div>



                </div>
                <div className="container width_2 mt-5">
                    <h4><b>Sharing Your Personal Information</b></h4>
                    <p>
                        We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:

                    </p>

                    <div
                        className='px-5'>
                        <ul >
                            <li>
                                We may share your Personal Information with third-party vendors that assist us in operating the App, processing orders, and providing customer service.

                            </li>
                            <li>
                                We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant, or other lawful requests for information we receive, or to otherwise protect our rights.
                            </li>

                        </ul>
                    </div>


                </div>
                <div className="container width_2 mt-5">
                    <h2><b>Behavioural Advertising</b></h2>

                    <p>
                        As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.
                        For more information about how targeted advertising works, you can visit the <a href="#"> Network Advertising Initiative’s (“NAI”) educational page.</a>
                    </p>
                    <p>
                        You can opt out of targeted advertising by:
                    </p>



                    <div
                        className='px-5'>
                        <ul >
                            <li>
                                <b>  Facebook: </b>
                                <a href="https://www.facebook.com/settings/?tab=ads" target='_blank'>
                                    https://www.facebook.com/settings/?tab=ads
                                </a>
                            </li>
                            <li>
                                <b>  Google:  </b>
                                <a href="https://www.google.com/settings/ads/anonymous" target='_blank'>
                                    https://www.google.com/settings/ads/anonymous
                                </a>
                            </li>
                            <li>
                                <b>  Bing: </b>
                                <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" target='_blank'>
                                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p>
                        Additionally, you can opt-out of some of these services by visiting the
                        <a href="#">
                            {' '}Digital Advertising Alliance’s opt-out portal.
                        </a>

                    </p>


                </div>
                <div className="container width_2 mt-5">
                    <h2><b> Your Rights</b></h2>

                    <p>
                        If you are a European resident, you have the right to access personal information we hold about you and to
                        ask that your personal information be corrected, updated, or deleted.
                        If you would like to exercise this right, please contact us through the contact information below.<br />
                        <br />
                        Additionally, if you are a European resident, we note that we are processing your information in order to
                        fulfill contracts we might have with you (for example, if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Please note that your information will be transferred outside of Europe, including to Canada and the United States.
                    </p>
                </div>

                <div className="container width_2 mt-5">
                    <h2><b>  Data Retention</b></h2>

                    <p>
                        When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                        Changes
                        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                    </p>
                </div>

                <div className="container width_2 mt-5">
                    <h2><b> Contact Us</b></h2>

                    <p>

                        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us
                        by e-mail at <b>support@sofia-app.io</b>  or by mail using the details provided below:  </p>
                </div>
                <div className="container width_2 mt-5">
                    <h2><b>    M&K Marketing LTD Oy</b></h2>

                    <p>
                        Kivisillantie 8,
                        05810 Hyvinkää,
                        Finland
                    </p>
                </div>







            </page>
        </div>

    )
}
export default CopyRightPage