import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const FourthContentBox = ({
  text,
  heading,
  handleSectionEditChange,
  image,
  section4Image,
  salesTextHeadline3,
}) => {
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading]);
  const handleSectionImageClick = () => {
    handleSectionEditChange(["section4Image"]);
  };
  const sectionText = DOMPurify.sanitize(content?.text);
  return (
    <div className="first-content-box-main-container">
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`first-content-box-right-container ${
          section4Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          <img
            className="first-content-box-right-container-image"
            src={image}
            alt=""
          />
        )}
      </div>
      <div className="second-content-box-left-container">
        <div
          onClick={() =>
            handleSectionEditChange(["salesTextHeadline3", "salesText3"])
          }
          style={{ cursor: "pointer" }}
          className={`fourth-container-left-box ${
            salesTextHeadline3 ? "outline-border-selected" : "outline-border"
          }`}
        >
          {content?.heading ? <h3>{content?.heading}</h3> : ""}
          {content?.text ? (
            <div className="edit-formatted-text">
              <div
                style={{ color: "#373b42" }}
                dangerouslySetInnerHTML={{ __html: sectionText }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="fourth-container-left-box">
          <button className="product-shop-now-button-black">SHOP NOW</button>
        </div>
      </div>
    </div>
  );
};

export default FourthContentBox;
