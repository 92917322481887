import React from "react";
import "./MyProducts.css";
import Wrapper from "../Dashboard/Wrapper";
import ProductsStatusTable from "./ProductsStatusTable";
import CustomLoader from "../CustomLoader";
const MyProducts = () => {
  return <Wrapper>
    <div className="products-top-header">
        <h3>My Products</h3>
        <ProductsStatusTable/>
     <div className="bg-black">

      </div>   
        </div>
  </Wrapper>;
};

export default MyProducts;
