import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";

const FifthContentBox = ({
  text,
  heading,
  handleSectionEditChange,
  image,
  section5Image,
  socialProofHeadline,
}) => {
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading]);
  const sectionText = DOMPurify.sanitize(content?.text);

  const handleSectionImageClick = () => {
    handleSectionEditChange(["section5Image"]);
  };
  return (
    <div className="first-content-box-main-container">
      <div className="fifth-content-box-left-container">
        <div
          onClick={() =>
            handleSectionEditChange(["socialProofHeadline", "socialProofText"])
          }
          style={{ cursor: "pointer" }}
          className={`first-container-left-box ${
            socialProofHeadline ? "outline-border-selected" : "outline-border"
          }`}
        >
          {content?.heading ? (
            <h3 style={{ color: "#373b42" }}>{content?.heading}</h3>
          ) : (
            ""
          )}

          {content?.text ? (
            <div className="edit-formatted-text">
              <div className="edit-formatted-text">
                <div
                  style={{ color: "#373b42" }}
                  dangerouslySetInnerHTML={{ __html: sectionText }}
                ></div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`first-content-box-right-container ${
          section5Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          <img
            className="first-content-box-right-container-image"
            src={image}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default FifthContentBox;
