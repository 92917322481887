import { createSlice } from "@reduxjs/toolkit";




const productVariantsSlice = createSlice({
    name: "productVariantsSlice",

    initialState: {

        fillteredProductData: {},
        productData: null,
        productBulletBenefits: undefined,
        matchedVariant: {},
        propertiesArray: {},
        variantsDetails: {},
        uniqueValuesObject: {},
        globallyDisabledProperties: {},
        mediaImages: [],
        changeVariantImageKey: undefined,
        newImagesObject: {},
        startSelectionObject: {},
        selectedProperties: {},
        editViewStyle: '',
        disabling: true,
        enableDisable: false,
        focusedInput: null,
        propertiesLength: 0,
        totalEnabledVariants: null,
        totalVriants: null,
        totalAllowedVariants: 100,
        loading: false,
        success: false,
    },

    reducers: {
        setEditViewStyle: (state, action) => {
            const val = action.payload
            state.editViewStyle = val
        },
        resetProductDataInState: (state, action) => {
            // state.productData = 
            const initialProductState = {
                fillteredProductData: {},
                productData: null,
                productBulletBenefits: undefined,
                matchedVariant: {},
                propertiesArray: {},
                variantsDetails: {},
                uniqueValuesObject: {},
                globallyDisabledProperties: {},
                mediaImages: [],
                changeVariantImageKey: undefined,
                newImagesObject: {},
                startSelectionObject: {},
                selectedProperties: {},
                disabling: true,
                focusedInput: null,
                propertiesLength: 0,
                totalEnabledVariants: null,
                totalVriants: null,
                totalAllowedVariants: 100,
                loading: false,
                success: false,
            };
            return initialProductState;
        },

        setProductDataInState: (state, action) => {
            // state.productData = 
            state.productBulletBenefits = action.payload.data.productTemplate.bulletBenefits;
            const { data } = action.payload
            const { product_variants } = data
            state.totalEnabledVariants = product_variants.filter(item => item.is_disabled === false).length;
            const propertiesCount = [];
            let uniqueProperties = {};
            let isGloballyDisbled = [];

            product_variants.forEach(variant => {
                variant.variant_properties.forEach(prop => {
                    if (!uniqueProperties[prop.shopify_property_label]?.includes(prop.shopify_property_value) ?? false) {
                        uniqueProperties = {
                            ...uniqueProperties,
                            [prop.shopify_property_label]: [...(uniqueProperties[prop.shopify_property_label] || []), prop.shopify_property_value]
                        };
                    }
                });
            });


            product_variants.forEach(variant => {
                variant.variant_properties.forEach(prop => {
                    Object.entries(uniqueProperties).forEach(([property, values]) => {
                        values.forEach(value => {
                            if (prop.shopify_property_label === property && value === prop.shopify_property_value) {
                                propertiesCount.push({ [property]: value });
                                if (variant.is_disabled) {
                                    isGloballyDisbled.push({ [property]: value, isDisabled: variant.is_disabled });
                                }
                            }
                        });
                    });
                });
            });



            const countOccurrences = (array) => {
                const counts = {};
                array.forEach(item => {
                    Object.keys(item).forEach(key => {
                        if (key !== 'isDisabled') {
                            if (!counts[key]) {
                                counts[key] = {};
                            }
                            const value = item[key];
                            counts[key][value] = (counts[key][value] || 0) + 1;
                        }
                    });
                });
                return counts;
            };

            // Function to check occurrences and perform functionality
            const checkOccurrences = (counts) => {
                Object.entries(counts).forEach(([property, values]) => {
                    Object.entries(values).forEach(([value, count]) => {
                        if (count === propertiesCounts[property][value]) {
                            console.log(` %c The ${property} "${value}" appears ${count} times DISABLED & ${propertiesCounts[property][value]} is ENABLED now it is globally disabled.`, 'color: red; font-weight:bold');
                            if (!state.globallyDisabledProperties[property]?.includes(value) ?? false) {
                                state.globallyDisabledProperties = {
                                    ...state.globallyDisabledProperties,
                                    [property]: [...(state.globallyDisabledProperties[property] || []), value]
                                };
                            }

                        } else {
                            console.log(` %c The ${property} "${value}" appears ${count} times  DISABLED & ${propertiesCounts[property][value]} is ENABLED now it is not globally disabled.`, 'color: yellow; font-weight:bold');
                            const updatedGlobalProperties = { ...state.globallyDisabledProperties };
                            if (updatedGlobalProperties[property]) {
                                updatedGlobalProperties[property] = updatedGlobalProperties[property].filter(v => v !== value);
                                if (updatedGlobalProperties[property].length === 0) {
                                    delete updatedGlobalProperties[property];
                                }
                            }
                            state.globallyDisabledProperties = updatedGlobalProperties;
                        }
                    });
                });
            };

            const counts = countOccurrences(isGloballyDisbled);
            const propertiesCounts = countOccurrences(propertiesCount);
            checkOccurrences(counts);


            // const updated = product_variants.map((item) => {
            //     return {
            //         ...item,
            //         ['enableSalePrice']: false,
            //     };
            // });
            state.productData = {
                ...action.payload,
                data: {
                    ...data,
                    product_variants: product_variants,
                },
            };
        },
        changeProductBullets: (state, action) => {
            state.productBulletBenefits = action.payload;
        },

        changeSelectedVariantImage: (state, action) => {
            const newImage = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;

            const updatedPropertiesArray = product_variants.map((item) => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map((prop) => {
                        if (prop.shopify_property_value === state?.changeVariantImageKey) {
                            return {
                                ...prop,
                                image: newImage,
                            };
                        }
                        return prop;
                    }),
                };
            });

            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray,
                },
            };
            state.changeVariantImageKey = undefined;
        },
        setChangeVariantImageKey: (state, action) => {
            state.changeVariantImageKey = action?.payload;
        },

        setMediaImages: (state, action) => {
            const updatedImages = [...state.mediaImages, ...action.payload];
            state.mediaImages = updatedImages;
        },

        setMatchingVariant: (state, action) => {
            state.matchedVariant = action.payload
        },
        editRegularPrice: (state, action) => {
            state.matchedVariant = action.payload
        },
        editSalePrice: (state, action) => {
            const updated = { ...state.matchedVariant }
            updated.sku_sale_price = 5

            state.matchedVariant
                = updated
        },

        setVariantProperties: (state, action) => {

            const { propertiesArray,
                variantsDetails,
                uniqueValuesObject,
                productimages,
                newImagesObject } = action.payload


            state.propertiesArray = propertiesArray;
            state.variantsDetails = variantsDetails;
            state.uniqueValuesObject = uniqueValuesObject;
            state.newImagesObject = newImagesObject;

            // state.startSelectionObject = startSelectionObject;
        },

        setSelectedVariantProperties: (state, action) => {
            state.selectedProperties = action.payload
        },

        setPriceOfAllVariants: (state, action) => {
            const { price, salePrice } = action.payload
            const { data } = state.productData;
            const { product_variants } = data;
            const updatedPropertiesArray = product_variants.map(item => {
                return {
                    ...item,
                    discounted_price: parseInt(salePrice),
                    price: parseInt(price),
                };
            });


            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray
                }
            };

        },

        changeLabelNameOfProperties: (state, action) => {
            const { value, property } = action.payload;
            const { data } = state.productData;

            const { product_variants } = data;

            if (state.globallyDisabledProperties[property]?.length > 0) {
                const oldData = [...state.globallyDisabledProperties[property]]
                const globalVariables = { [value]: oldData }
                state.globallyDisabledProperties = { ...state.globallyDisabledProperties, ...globalVariables }
                delete state.globallyDisabledProperties[property];
            }

            const updatedPropertiesArray = product_variants.map(item => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map(prop => {
                        if (prop.shopify_property_label === property) {

                            return {
                                ...prop,
                                shopify_property_label: value
                            };
                        }
                        return prop;
                    })
                };
            });


            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray
                }
            };

        },



        changePriceAndDiscountOfVariants: (state, action) => {
            const { propertyValue, property, value: initialValue, toChange, shipsFrom } = action.payload
            const value = initialValue.replace(/^0+(?!$)/, '');

            if (value < 0 || value > 10000) {
                return
            }
            const { data } = state.productData;
            const { product_variants } = data;

            // console.log('change price');
            let variants = [];


            // Find the variant that matches the given property and value
            product_variants.map(item => {
                item.variant_properties.map(prop => {
                    if (prop.shopify_property_value === propertyValue
                    ) {
                        variants.push(item)
                    }
                });
            });
            console.log(':value to be price change::====', JSON.parse(JSON.stringify(variants)));

            if (variants.length < 1) {
                return;
            }

            if (toChange === 'price') {
                const updatedVariants = product_variants.map(item => {
                    const matched = variants.find(v => v.aliexpress_sku === item.aliexpress_sku)
                    if (matched) {
                        return {
                            ...item,
                            price: value,
                        };
                    }
                    return item;
                });

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };

            } else {
                const updatedVariants = product_variants.map(item => {
                    const matched = variants.find(v => v.aliexpress_sku === item.aliexpress_sku)

                    if (matched) {
                        return {
                            ...item,
                            discounted_price: value,
                        };
                    }
                    return item;
                });

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };
            }

        },
        enableDiscountOfVariants: (state, action) => {
            const { propertyValue, properties, onOff, parentChild, shipsFrom } = action.payload
            const { data } = state.productData;
            const { product_variants } = data;

            console.log('change switch:', onOff, propertyValue);
            let variants = [];
            let matchedProp = []

            if (parentChild) {


                // Find the variant that matches the given property and value
                product_variants.map(item => {
                    item.variant_properties.map(prop => {
                        if (prop.shopify_property_value === propertyValue
                        ) {
                            variants.push(item)
                        }
                    });
                });

                const updatedVariants = product_variants.map(item => {
                    const matched = variants.find(v => v.aliexpress_sku === item.aliexpress_sku)

                    if (matched) {
                        return {
                            ...item,
                            enableSalePrice: !onOff,
                            // discounted_price: value,
                        };
                    }
                    return item;
                });

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };

            } else {
                const updated = product_variants.map(variant => {
                    variant.variant_properties.map((prop, i) => {
                        if (properties[prop.shopify_property_label] === prop.shopify_property_value
                            || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)
                        ) {
                            matchedProp.push(true)

                        }
                    })

                    if (matchedProp.length === variant.variant_properties.length) {

                        // disable = variant.is_disabled
                        matchedProp = []

                        return {
                            ...variant,
                            enableSalePrice: !onOff,

                        }
                    }
                    matchedProp = []
                    return variant
                })

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updated
                    }
                };

            }



        },
        enableDiscountOfSpecific: (state, action) => {

        },
        changePriceAndDiscountOfSpecific: (state, action) => {
            const { toChange, properties, value, shipsFrom } = action.payload
            if (value < 0) {
                return
            }
            let matchedProp = []
            const { data } = state.productData;
            const { product_variants } = data;


            if (toChange === 'price') {
                const updated = product_variants.map(variant => {
                    variant.variant_properties.map((prop, i) => {
                        if (properties[prop.shopify_property_label] === prop.shopify_property_value ||
                            (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                            matchedProp.push(true)

                        }
                    })

                    if (matchedProp.length === variant.variant_properties.length) {

                        // disable = variant.is_disabled
                        matchedProp = []

                        return { ...variant, price: value }
                    }
                    matchedProp = []
                    return variant
                })

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updated
                    }
                };
            } else {
                const updated = product_variants.map(variant => {
                    variant.variant_properties.map((prop, i) => {
                        if (properties[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                            matchedProp.push(true)

                        }
                    })

                    if (matchedProp.length === variant.variant_properties.length) {

                        // disable = variant.is_disabled
                        matchedProp = []

                        return { ...variant, discounted_price: value }
                    }
                    matchedProp = []
                    return variant
                })

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updated
                    }
                };


            }




        },
        disableVariant: (state, action) => {
            const { property, value, is_disabled, shipsFrom } = action.payload;
            // to update the selected properties at product template 
            state.enableDisable = !state.enableDisable

            if (is_disabled) {
                if (!state.globallyDisabledProperties[property]?.includes(value) ?? false) {
                    state.globallyDisabledProperties = {
                        ...state.globallyDisabledProperties,
                        [property]: [...(state.globallyDisabledProperties[property] || []), value]
                    };
                }
            } else {
                const updatedProperties = { ...state.globallyDisabledProperties };
                if (updatedProperties[property]) {
                    updatedProperties[property] = updatedProperties[property].filter(v => v !== value);
                    if (updatedProperties[property].length === 0) {
                        delete updatedProperties[property];
                    }
                }
                state.globallyDisabledProperties = updatedProperties;
            }



            const isAllDisabled = state.uniqueValuesObject[property]?.length <= state.globallyDisabledProperties[property]?.length;

            // console.log("================= variants info =====================")
            // console.log(JSON.parse(JSON.stringify(state.globallyDisabledProperties)));
            // console.log("all disabled ",isAllDisabled);
            // console.log("================= variants info =====================")

            if (isAllDisabled) {

                const updatedProperties = { ...state.globallyDisabledProperties };
                if (updatedProperties[property]) {
                    updatedProperties[property] = updatedProperties[property].filter(v => v !== value);
                    if (updatedProperties[property].length === 0) {
                        delete updatedProperties[property];
                    }
                }
                state.globallyDisabledProperties = updatedProperties;

            } else {

                const { data } = state.productData;
                const { product_variants } = data;
                state.disabling = !state.disabling
                state.totalEnabledVariants = product_variants.filter(item => item.is_disabled === false).length;
                state.totalVriants = product_variants.length

                let variant = [];

                // Find the variant that matches the given property and value
                product_variants.map(item => {
                    item.variant_properties.map(prop => {
                        if (prop.shopify_property_value === value) {
                            variant.push(item)
                        }
                    });
                });
                // console.log(':value to be disabled', JSON.parse(JSON.stringify(variant)));

                if (variant.length < 1) {
                    return;
                }

                let disabled = []
                // Create a new array of product_variants with the updated is_disabled property
                const updatedVariants = product_variants.map(item => {
                    const matched = variant.find(v => v.aliexpress_sku === item.aliexpress_sku)

                    if (matched) {

                        const isDisabled = matched.variant_properties.some(property_name =>
                            state.globallyDisabledProperties[property_name.shopify_property_label]?.includes(property_name.shopify_property_value)
                        );

                        return {
                            ...item,
                            is_disabled: isDisabled ? true : is_disabled
                        };

                    }
                    return item;
                });


                // Update the state with the modified product variants
                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };


                state.totalEnabledVariants = updatedVariants.filter(item => item.is_disabled === false).length;
                state.totalVriants = updatedVariants.length

                console.log(" %c ----------------    PRODUCT VARIANTS ----------------", 'color: blue; font-weight:bold');
                updatedVariants.forEach(item => {
                    console.log("%c ########  VARIANT   #########", 'color: green; font-weight:bold');
                    item.variant_properties.forEach(prop => {
                        console.log(`%c VARIANT => LABEL => ${prop.shopify_property_label}           VALUE => ${prop.shopify_property_value}                        IS_DISABLES => ${item.is_disabled} \n`, `color: ${item.is_disabled ? "red" : "green"}; font-weight:bold`)
                    });
                    console.log("%c ########  VARIANT   ######### \n", 'color: green; font-weight:bold');
                });
                console.log(" %c ----------------    PRODUCT VARIANTS ----------------", 'color: blue; font-weight:bold');

            }

        },
        disableSpecificProperties: (state, action) => {
            const { properties, isDisabled, shipsFrom } = action.payload
            console.log(shipsFrom, ':::in redux ships from');

            const { data } = state.productData;
            const { product_variants } = data;

            if (product_variants.filter(item => item.is_disabled === false).length <= 1 && isDisabled == true) {
                return;
            }

            let matchedProp = [];

            let getVariant = {};

            let isGloballyDisbled = [];

            // Find the variant that matches the given property and value
            product_variants.forEach(variant => {

                variant.variant_properties.forEach(prop => {

                    if (properties[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                        matchedProp.push(true)
                    }
                });
                // const lengthToCheckOfProperties = shipsFrom ? variant.variant_properties.length - 1 : variant.variant_properties.length
                if (matchedProp.length === variant.variant_properties.length) {

                    getVariant = variant
                    matchedProp = []
                    return
                }
                matchedProp = []
            });

            // let disabled = []
            // Create a new array of product_variants with the updated is_disabled property
            const updatedVariants = product_variants.map(item => {
                // const matched = variant.find(v => v.aliexpress_sku === item.aliexpress_sku)
                if (item.aliexpress_sku === getVariant.aliexpress_sku) {
                    // disabled.push(getVariant.variant_properties)

                    return {
                        ...item,
                        is_disabled: isDisabled
                    };
                }
                return item;
            });

            state.totalEnabledVariants = updatedVariants.filter(item => item.is_disabled === false).length;
            state.totalVriants = updatedVariants.length


            const propertiesCount = [];
            updatedVariants.forEach(variant => {
                variant.variant_properties.forEach(prop => {
                    Object.entries(state.uniqueValuesObject).forEach(([property, values]) => {
                        values.forEach(value => {
                            if (prop.shopify_property_label === property && value === prop.shopify_property_value) {
                                propertiesCount.push({ [property]: value });
                                if (variant.is_disabled) {
                                    isGloballyDisbled.push({ [property]: value, isDisabled: variant.is_disabled });
                                }
                            }
                        });
                    });
                });
            });



            const countOccurrences = (array) => {
                const counts = {};
                array.forEach(item => {
                    Object.keys(item).forEach(key => {
                        if (key !== 'isDisabled') {
                            if (!counts[key]) {
                                counts[key] = {};
                            }
                            const value = item[key];
                            counts[key][value] = (counts[key][value] || 0) + 1;
                        }
                    });
                });
                return counts;
            };

            // Function to check occurrences and perform functionality
            const checkOccurrences = (counts) => {
                Object.entries(counts).forEach(([property, values]) => {
                    Object.entries(values).forEach(([value, count]) => {
                        if (count === propertiesCounts[property][value]) {
                            console.log(` %c The ${property} "${value}" appears ${count} times DISABLED & ${propertiesCounts[property][value] - count} is ENABLED now it is globally disabled.`, 'color: red; font-weight:bold');
                            if (!state.globallyDisabledProperties[property]?.includes(value) ?? false) {
                                state.globallyDisabledProperties = {
                                    ...state.globallyDisabledProperties,
                                    [property]: [...(state.globallyDisabledProperties[property] || []), value]
                                };
                            }

                        } else {
                            console.log(` %c The ${property} "${value}" appears ${count} times  DISABLED & ${propertiesCounts[property][value] - count} is ENABLED now it is not globally disabled.`, 'color: yellow; font-weight:bold');
                            const updatedGlobalProperties = { ...state.globallyDisabledProperties };
                            if (updatedGlobalProperties[property]) {
                                updatedGlobalProperties[property] = updatedGlobalProperties[property].filter(v => v !== value);
                                if (updatedGlobalProperties[property].length === 0) {
                                    delete updatedGlobalProperties[property];
                                }
                            }
                            state.globallyDisabledProperties = updatedGlobalProperties;
                        }
                    });
                });
            };

            const counts = countOccurrences(isGloballyDisbled);
            const propertiesCounts = countOccurrences(propertiesCount);

            checkOccurrences(counts);
            // console.log("================= variants info =====================")
            // console.log(JSON.parse(JSON.stringify(state.globallyDisabledProperties)));
            // console.log("================= variants info =====================")


            // Update the state with the modified product variants
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedVariants
                }
            };
        },


        disablePropertyOfVariant: (state, action) => {
            const { property, value } = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;

            let variant = null;

            // Find the variant that matches the given property and value
            product_variants.forEach(item => {
                item.variant_properties.forEach(prop => {
                    if (prop.shopify_property_label === property && prop.shopify_property_value === value) {
                        variant = item;
                    }
                });
            });

            if (!variant) {
                return;
            }

            // Create a new array of product_variants with the updated is_disabled property
            const updatedVariants = product_variants.map(item => {
                if (item.aliexpress_sku === variant.aliexpress_sku) {
                    return {
                        ...item,
                        is_disabled: !item.is_disabled
                    };
                }
                return item;
            });

            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedVariants
                }
            };

        },
        changeLabelOfProperties: (state, action) => {
            const { value, property, propertyValue } = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;


            const updatedPropertiesArray = product_variants.map(item => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map(prop => {
                        if (prop.shopify_property_label === property && prop.shopify_property_value === propertyValue) {
                            return {
                                ...prop,
                                shopify_property_value: value
                            };
                        }
                        return prop;
                    })
                };
            });


            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray
                }
            };

        }
        ,
        changeSKU: (state, action) => {

            const { properties, value, id, shipsFrom } = action.payload;
            state.focusedInput = id
            const { data } = state.productData;
            const { product_variants } = data;

            const updatedVariants = product_variants.map(variant => {
                const isMatched = variant.variant_properties.every(prop =>
                    properties[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)
                );

                if (isMatched) {
                    return { ...variant, shopify_sku: value };
                }

                return variant;
            });

            state.productData.data.product_variants = updatedVariants;
        },
        // currently editing products id 

    },

});

export const {
    setProductDataInState,
    resetProductDataInState,
    changeProductBullets,
    setMatchingVariant,
    editSalePrice,
    editRegularPrice,
    setVariantProperties,
    setSelectedVariantProperties,
    changeLabelNameOfProperties,
    disableVariant,
    disableSpecificProperties,
    disablePropertyOfVariant,
    changeLabelOfProperties,
    changePriceAndDiscount,
    changePriceAndDiscountOfVariants,
    changePriceAndDiscountOfSpecific,
    setPriceOfAllVariants,
    setMediaImages,
    changeSelectedVariantImage,
    setChangeVariantImageKey,
    changeSKU,
    enableDiscountOfVariants,
    enableDiscountOfSpecific,
    setEditViewStyle,
} = productVariantsSlice.actions;

export default productVariantsSlice.reducer;


