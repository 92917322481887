const RenderMoreItem = ({ moreItem,value }) => {
  
    return (
        <div className="tab-label-container">
            <div>{moreItem}</div>
            <span className="orders-number-container">
                {value}
            </span>
        </div>
    )
}
export default RenderMoreItem