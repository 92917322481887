import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";

async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails:{loginId} } = await getCurrentUser();
    const result = await getCurrentUser();
    console.log(result,'----->>>>>>>----');
    

    return {userId,loginId};
  } catch (err) {
    console.log(err);
  }
}

export const getCurrentAuthenticatedSession = async () => {
  try {
    const session = await fetchAuthSession();
    if (session) {
      return session.tokens.accessToken;
    } else {
      window.location.href = "/";
    }
  } catch (error) {
    console.error("Error fetching session:", error);
    throw error; // Re-throw for handling in your component
  }
};

export default currentAuthenticatedUser;
