import { createSlice } from "@reduxjs/toolkit";




const shippingConfigSlice = createSlice({
    name: "shippingConfigSlice",

    initialState: {
        countryMethod: [
            { id: 0, country: '', method: [], issue: null }
        ]
    },
    reducers: {
        addAllMethodsAvailable: (state, action) => {
            const { id, methods } = action.payload
            const updated = state.countryMethod.map(p => {
                if (p.id === id) {
                    return { ...p, method: methods }
                }
                return p
            })
            state.countryMethod = updated
        },

        setCountryMethod: (state, action) => {

            state.countryMethod = action.payload
        },
        addNewCountry: (state, action) => {

            const add = [...state.countryMethod, {
                issue: null,
                id: state.countryMethod.length,
                country: '', method: []

            }]
            state.countryMethod = add

        },
        deleteCountry: (state, action) => {
            const { id } = action.payload
            const updated = state.countryMethod.filter((d, i) => d.id !== id)
            state.countryMethod = updated

        },
        addMethod: (state, action) => {

            const { selectedRows, id } = action.payload
            const updated = state.countryMethod.map((d, i) => {
                if (parseInt(d.id) === parseInt(id)) {
                    return {
                        ...d,
                        method: d.method.map(m => {
                            if(selectedRows[0].shippingService===m.shippingService){
                                return {...m, status:true}
                            }
                            return {...m, status:false}

                        }),
                    }
                }
                return d;
            })
            state.countryMethod = updated
        },
        addCountry: (state, action) => {
            const { value, id } = action.payload
            const updated = state.countryMethod.map((d, i) => {
                if (d.id === id) {
                    return { ...d, country: value , method:[]}
                }
                return d;
            })
            state.countryMethod = updated
        }
    }

});

export const {
    addAllMethodsAvailable,
    setCountryMethod,
    addMethod,
    addNewCountry,
    addCountry,
    deleteCountry,

} = shippingConfigSlice.actions;

export default shippingConfigSlice.reducer;

