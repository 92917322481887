import React, { useEffect, useState } from "react";
import duck1 from "../../../assets/duckimages/duck1.jpeg";
import duck2 from "../../../assets/duckimages/duck2.jpeg";
import duck3 from "../../../assets/duckimages/duck3.jpeg";
import duck4 from "../../../assets/duckimages/duck4.jpeg";
import duck5 from "../../../assets/duckimages/duck5.jpeg";
import duck6 from "../../../assets/duckimages/duck6.jpeg";
import duck7 from "../../../assets/duckimages/duck7.jpeg";
import  chevronLeft from "../../../assets/chevron left.svg"
import  chevronRight from "../../../assets/chevron right.svg"
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

import "./ImageSection.css";

const ProductGallery = ({ images, handleSectionEditChange, galleryImages }) => {
  const [mainImage, setMainImage] = useState("");
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    setMainImage(images[0]);
  }, [images]);
  
  const handleNext = () => {
    if (startIndex + 5 < images.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };
  const handleSectionImageClick = () => {
    handleSectionEditChange(["gallery"]);
  };
  return (
    <div
      className={`image-section-main-container ${
        galleryImages ? "outline-border-selected" : "outline-border"
      }`}
    >
      {/* <div className="imge-seciton-image-container"> */}
      {images && (
        <img
          onClick={handleSectionImageClick}
          style={{ cursor: "pointer" }}
          className="gallery-main-image"
          src={mainImage}
          alt=""
        />
      )}
      {/* </div> */}
      {images && (
        <div className="gallery-thumbnails-container">
          <button
            onClick={handlePrev}
            disabled={startIndex === 0}
            className="arrow-button"
          >
            {/* <FaCaretLeft /> */}
            <img src={chevronLeft} alt="" />
          </button>
          <div className="gallery-thumbnails-wrapper">
            <div
              className="gallery-thumbnails"
              style={{ transform: `translateX(-${startIndex * 110}px)` }}
            >
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`"image: ", ${index}`}
                  className="thumbnail"
                  onClick={() => setMainImage(image)}
                />
              ))}
            </div>
          </div>
          <button
            onClick={handleNext}
            disabled={startIndex + 5 >= images?.length}
            className="arrow-button"
          >
            <img src={chevronRight} alt="" />

            {/* <FaCaretRight /> */}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductGallery;
