import React, { useState } from "react";
import greenbullet from "../../../assets/greenbullet.svg";
import { parseBulletPoints } from "../../../Utilities/ParseBulletPoints";
import { useSelector } from "react-redux";

const FourthContentBox = ({
  content,
  handleSectionEditChange,
  image,
  section3Image,
  bulletBenefits,
}) => {
  const { productBulletBenefits } = useSelector(
    (store) => store?.productVariants
  );

  const handleSectionImageClick = () => {
    handleSectionEditChange(["section3Image"]);
  };

  return (
    <div className="first-content-box-main-container">
      <div
        onClick={() => handleSectionEditChange(["bulletBenefits"])}
        style={{ cursor: "pointer" }}
        className="second-content-box-left-container"
      >
        <div
          className={`third-container-left-box ${
            bulletBenefits ? "outline-border-selected" : "outline-border"
          }`}
        >
          <ul className="product-bullet-points-list">
            {content
              ? parseBulletPoints(productBulletBenefits)?.map((point, index) => (
                  // ? content?.map((point, index) => (
                  <li key={index} className="product-bullet-point-item">
                    <img
                      src={greenbullet}
                      alt=""
                      className="productbullet-point-icon"
                    />
                    <span className="product-bullet-point-text">{point}</span>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer", width: "50%" }}
        className={`first-content-box-right-container" ${
          section3Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          <img
            className="first-content-box-right-container-image"
            src={image}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default FourthContentBox;
