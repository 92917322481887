import React, { useEffect, useState } from "react";
import trashbin from "../../../../assets/trashbin.svg";
import editpencil from "../../../../assets/editpencil.svg";
import cameraIcon from "../../../../assets/cameraIcon.svg";

import ImagesGalleryModal from "../../RightSection/ImagesGalleryModal";

import "./VariantsImageGallery.css";
import VariantsPropertiesContainer from "../VariantsProptiesContainer/VariantsProptiesContainer";
import { useDispatch, useSelector } from "react-redux";
import checkVariantDisable from "../../../../Utilities/productVariantsHandlers/checkVariantDisable";
import { setChangeVariantImageKey } from "../../../../redux/slices/product-variants-slice";
const VariantsImageGallery = ({
    property,
    productImages,
    setProductImages,
    aliexpressImages,
    productVariantDetails,
    toIterate
}) => {
    const { productData, globallyDisabledProperties } = useSelector(store => store.productVariants)
    const { data } = productData;
    const { product_variants } = data
    const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
    const [editImageIndex, setEditImageIndex] = useState();
    const [multipleFlag, setMultipleFlag] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered index
    const { mediaImages, changeVariantImageKey } = useSelector(
        (store) => store?.productVariants
    );
    const dispatch = useDispatch();
    const handleImageEdit = (index, col) => {
        dispatch(setChangeVariantImageKey(col));
        setEditImageIndex(index);
        setIsImagesModalVisible(true);
    };


    const handleImageDelete = (indexToRemove) => {
        setProductImages((prevArray) =>
            prevArray.filter((item, index) => index !== indexToRemove)
        );
    };
    const handleAddMediaClick = () => {
        setMultipleFlag(true);
        setIsImagesModalVisible(true);
    };
    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    const handleEyeClick = () => {

    }
    return (
        <div className=" grid grid-cols-2 xl:grid-cols-3 gap-2 mb-[32px]">
            {toIterate[property]?.map((value, index) => {

                // let disable = checkVariantDisable(product_variants, property, value)
                const disable = globallyDisabledProperties[property]?.includes(value) ?? false;
                return (<div key={index} className="flex flex-col  ">
                    <div
                        key={index}
                        style={{
                            // backgroundImage: `url(${productImages[value]?productImages[value]:''})` 
                        }}
                        className={`gallery-image-edit-container-variants ${disable && 'opacity-[0.5] hover:cursor-not-allowed'}`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <img className="sku-img-styles !h-[118px]" src={productImages[value]?productImages[value] : ''} alt="" />

                        {disable ? null : hoveredIndex === index && (
                            <div className="background-gallery-image-overlay-edit-image-section">
                                <div className="edit-image-upper-portion">
                                    <button
                                        className="gallery-image-edit-button"
                                        onClick={() => handleImageEdit(index, value)}
                                    >
                                        <img src={editpencil} alt="edit" />
                                    </button>
                                </div>

                            </div>
                        )}

                    </div>
                    <VariantsPropertiesContainer property={property} value={value} disable={disable} />

                </div>

                )
            })}


            <ImagesGalleryModal
                aliexpressImages={aliexpressImages}
                multipleFlag={multipleFlag}
                openModal={isImagesModalVisible}
                productImages={productImages}
                setProductImages={setProductImages}
                setIsImagesModalVisible={setIsImagesModalVisible}
                imageindex={editImageIndex}
                setMultipleFlag={setMultipleFlag}
                productImageFlag={true}
            />
        </div>

    );
};

export default VariantsImageGallery;
