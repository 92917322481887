import { Modal, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import './ReplaceVariantsModal.css'
import CustomLoader from "../../../CustomLoader";


const ReplaceVariantsModal = ({ open, setOpen, matchingVariants, setMatchingVarints }) => {
    const [replaceButtonLoading, setReplaceButtonLoading] = useState(false)
    const [selectedIds, setSelectedIds] = useState(['0'])
    const [data, setData] = useState([])
    const [rows, setRows] = useState([])
    useEffect(() => {
        if (matchingVariants) {
            setData(matchingVariants)
            setTimeout(() => {

                const row = document.querySelectorAll('.ant-table-row')
                row.forEach(ele => {
                    if (ele.getAttribute('data-row-key') === '0') {
                        ele.classList.remove('ant-table-row-selected');
                        ele.style.setProperty('background-color', '#f4f6f7', 'important');
                    }
                })
            }, 500);
        }
    }, [matchingVariants])


    useEffect(() => {
        if (data) {
            const row = document.querySelectorAll('.ant-table-row')
            row.forEach(ele => {
                if (ele.getAttribute('data-row-key') === '0') {
                    ele.classList.remove('ant-table-row-selected');
                    ele.style.setProperty('background-color', '#f4f6f7', 'important');
                }
            })
        }
    }, [data])

    const columns = [
        {
            title: <p className="replace-modal-para">Select</p>,
            dataIndex: 'SkuDetails',
            render: (text, record, id) => {
                return (
                    <SkuRenderer key={id} rows={rows}
                        record={record} rowId={id} />
                )
            },
            width: '97%',
        },

    ];

    const handleCancel = () => {
        setOpen(false);
        setMatchingVarints([])

    };

    const handleConfirm = async () => {
    };

    const ConfirmButton = () => {
        return (
            <div className="footer-container">
                <button onClick={handleCancel} className="product-modal-cancel-button !w-full">
                    Back
                </button>

                <button onClick={() => {
                    setReplaceButtonLoading(true)

                    setTimeout(() => {
                        setReplaceButtonLoading(false)
                    }, 2000);
                }} className="modal-import-button !w-full">
                    {!replaceButtonLoading ? (
                        "Replace in orders"
                    ) : (
                        <span className="flex justify-center items-center gap-2">
                            <p>
                                Replacing in orders
                            </p>
                            <CustomLoader width='20px' />
                        </span>
                    )}
                </button>

            </div>
        );
    };

    const rowSelection = {
        selectedRowKeys: selectedIds,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedIds(selectedRowKeys)
            setRows(selectedRows)
            setSelectedIds(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.key === '0',
        }),
    };

    return (
        <div>
            <Modal
                style={{
                    right: '-27%',
                }}
                width={'580px'}
                closable={true}
                open={open}
                onOk={handleConfirm}
                footer={<ConfirmButton />}
                onCancel={handleCancel}
                okButtonProps={{
                    className: replaceButtonLoading
                        ? "modal-import-button modal-import-button-disabled"
                        : "modal-import-button",
                    disabled: replaceButtonLoading,
                }}
                cancelButtonProps={{
                    className: replaceButtonLoading
                        ? "product-modal-cancel-button-disabled"
                        : "product-modal-cancel-button",
                    disabled: replaceButtonLoading,
                }}
            >
                <div>
                    <div className="p-[20px] w-[90%]">

                        <p className="replace-modal-heading">We have detected other blocked orders with the same variants out of stock</p>
                        <p className="replace-modal-para">Would you like to replace this variant in other blocked orders as well?</p>
                    </div>
                    <Table
                        style={{ marginBottom: '24px', }}
                        pagination={false}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={data}
                    />
                </div>
            </Modal>
        </div>
    )
}
export default ReplaceVariantsModal

const SkuRenderer = ({ rows, rowId, record }) => {
    const checkSelected = (rowId) => {
        if (rowId === 0) {
            return false
        }
        return rows.find(f => f.key === rowId + 1) ? true : false
    }

    const {
        orderDate,
        order_number,
        total, image
    } = record
    const isSelected = checkSelected(rowId)
    return (
        <>
            <div
                className="flex  items-center justify-between p-[15px]">
                <div className="flex gap-3 items-center ">
                    <span className="replace-img-container border border-[lightgrey]">
                        <img className="rounded-[8px]  " src={image} alt={image} />
                    </span>
                    <span>
                        <p className={`replace-modal-order-id ${isSelected ? '!text-[#53389e]' : 'text-[lightgrey]'}`}>Order # {order_number}</p>
                        <p className={`replace-modal-order-details ${isSelected ? '!text-[#7f56d9]' : ''}`}>Purchase date {orderDate}</p>
                        <p className={`replace-modal-order-details ${isSelected ? '!text-[#7f56d9]' : ''}`}>Total order value {total}</p>
                    </span>
                </div>
                {
                    rowId === 0 && <span className="currently-handled">
                        Currently handled order
                    </span>
                }

            </div>

        </>
    )
}


// rowSelection object indicates the need for row selection

