import React from "react";
import "../../Style.css";
const LoaderWrapper = ({ children, loadingText, loading }) => {
  return (
    <div  className={` ${loading ? 'w-full   max-h-[100vh] overflow-hidden':' w-full h-auto'}`}>
    <div  className={` ${loading ? 'w-full   max-h-[100vh] overflow-hidden':' w-full h-auto'}`}>
        {children}</div>
      {loading && (
        <div className="w-full max-h-[100vh] overflow-hidden">
          <div className="loading-screen">
            <div className="progress-circle">
              <div className="ring">
                <div className="loading-line" />
              </div>
              <div className="loading-text">{loadingText}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoaderWrapper;
