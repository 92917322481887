import { createSlice } from "@reduxjs/toolkit";
import { json } from "react-router";
import findALiExpressSkuFromVariants from "../../Utilities/findALiExpressSkuFromVariants";
import useVariantsData from "../../Components/hooks/useVariantsData";


// export default 
// payload protoptype
const payload = {
    payload: {
        isShippingMethod: false,
        product_id: '',
        existing_product: {
            order_id: '',
            aliexpress_product_sku: '',
            aliexpress_product_id: '',

        },
        replacing_product: {
            aliexpress_product_id: '',
            aliexpress_product_sku: '',
            apply_to_all: false,

        },
        isShippingMethod: false,

        "newShippingMethod": {
            "isShippingMethod": true,
            "product_id": "7214249869425",
            "country": "AT",
            "status": true,
            "key": "2",
            "method_carrier": "",
            "issue": "",
            "cost": 0,
            "costUnit": "USD",
            "shippingService": "FedEx Express",
            "estimatedDeliveryTime": "3-5 days",
            "tracking": "Available"
        }
    }
}
const replaceVariantSlice = createSlice({
    name: "replaceVariantSlice",

    initialState: {
        refreshOrdersTable: false,
        record: {},
        productsObject: {},
        payload: [],
        ApiPayload: [],
        replacedVariants: {},
        blockedLowStockOrders: [],
        preSelectedVariantsProperties: {},
        skuNotFound: {},
        matchedProperties: {},
        invalidProduct: {},
        success: {},
        formWarning: {},
        data:{},
        // produc
    },
    reducers: {
        setSuccessAndWarningAtReplaceVariants: (state, action) => {
            const { formWarning, success,itemOrderId } = action.payload
            console.log('sussseeeed');
            state.success[itemOrderId]=success;
            state.formWarning[itemOrderId]=formWarning
            
        },
        setImportedShippingData: (state, action) => {
            const { data, itemOrderId } = action.payload
            console.log('sussseeeed');
            state.data[itemOrderId]=data;
         
            
        },
        refreshOrdersTableController: (state) => {

            state.refreshOrdersTable = !state.refreshOrdersTable
        },
        setReplacedVariants: (state, action) => {
            state.refreshOrdersTable = !state.refreshOrdersTable;
            const { itemOrderId, recordId } = action.payload
            if (state.replacedVariants[recordId]) {
                const updated = [...state.replacedVariants[recordId], itemOrderId]
                state.replacedVariants[recordId] = updated
            }
            else {
                state.replacedVariants[recordId] = [itemOrderId]
            }

        },
        setOrderRecord: (state, action) => {
            state.record = action.payload
        },
        resetReplcaeVariantState: (state, action) => {
            state.refreshOrdersTable = false;
            state.record = {};
            state.productsObject = {};
            state.payload = [];
            state.ApiPayload = [];
            state.replacedVariants = {};
            state.blockedLowStockOrders = [];
            state.preSelectedVariantsProperties = {};
            state.skuNotFound = {};
            state.matchedProperties = {};
            state.invalidProduct = {};
            state.data={}
        },
        addNewProductToReplaceWith: (state, action) => {
            const { itemOrderId, importedProduct, completeItem,
                aliexpress_product_sku: aliexpress_product_sku_existing,
                aliexpress_product_id: aliexpress_product_id_existing, missStockOrShipping, product_id } = action.payload

            state.matchedProperties[itemOrderId] = { match: true, message: 'Matched Success!' }
            state.invalidProduct[itemOrderId] = false

            const { data, product_id: aliexpress_product_id_replacing } = importedProduct
            const { product_variants } = data

            const shipsTo = product_variants.flatMap(variant =>
                variant.variant_properties.filter(property => property.shopify_property_label === 'Ships From').map(property => (property.shopify_property_value))
            );
            const shipsFromArray = [...new Set(shipsTo)]

            const transformUniqueValuesObjectFromImported = (product) => {
                // for imported product

                const { data } = product
                const { product_variants } = data

                const { variant_properties } = product_variants[0]
                const propertiesArray = variant_properties.map((p) => p.shopify_property_label)

                const uniqueValuesObject = propertiesArray.reduce((acc, prop) => {
                    const values =
                        variant_properties.filter(property => property.shopify_property_label === prop).map(property => {

                            return (property.shopify_property_value)
                        })

                    acc[prop] = [...new Set(values)];
                    return acc;
                }, {});

                const transformedNewProductObject = {};
                for (const key in uniqueValuesObject) {

                    if (uniqueValuesObject.hasOwnProperty(key)) {
                        const firstValue = uniqueValuesObject[key][0];
                        transformedNewProductObject[key] = '';
                    }
                }

                // for existing products variants
                const { aliexpres_response } = completeItem
                const { ae_item_sku_info_dtos } = aliexpres_response
                const { ae_item_sku_info_d_t_o } = ae_item_sku_info_dtos
                let product_variants_of_existing = ae_item_sku_info_d_t_o.find(f => f.sku_attr === aliexpress_product_sku_existing)

                if (!product_variants_of_existing) {
                    state.invalidProduct[itemOrderId] = true
                    return
                }

                const { ae_sku_property_dtos } = product_variants_of_existing
                const { ae_sku_property_d_t_o: variant_properties_of_existing } = ae_sku_property_dtos
                console.log(product_variants_of_existing, ':::foundvariant::', aliexpress_product_sku_existing,);

                const propertiesArrayOfExisting = variant_properties_of_existing.map((p) => p.sku_property_name)

                const uniqueValuesObjectFromExisting = propertiesArrayOfExisting.reduce((acc, prop) => {
                    const values =
                        variant_properties_of_existing.filter(property => property.sku_property_name === prop).map(property => {

                            return (property.property_value_definition_name ? property.property_value_definition_name : property.sku_property_value)
                        })

                    acc[prop] = [...new Set(values)];
                    return acc;
                }, {});
                console.log('cdbhsfsh from exiting prod,:', uniqueValuesObjectFromExisting);


                const transformedExistingObject = {};

                for (const key in uniqueValuesObjectFromExisting) {

                    if (uniqueValuesObjectFromExisting.hasOwnProperty(key)) {
                        const firstValue = uniqueValuesObjectFromExisting[key][0];
                        transformedExistingObject[key] = firstValue;
                    }
                }

                //  match the keys ie properties match or not 

                for (const key in transformedExistingObject) {
                    if (key !== 'Ships From' && !transformedNewProductObject.hasOwnProperty(key)) {
                        console.log('not matched property ', key, '=>', transformedExistingObject);
                        state.matchedProperties[itemOrderId] = {
                            match: false, message: ` ${key} Property not found in the
                         imported product, please import another product to replace with!` }
                    }
                }

                const result = Object.entries(transformedExistingObject).map(([key, value]) => [key, value]);
                return { extractStartValuesFromExisting: result, startExtractStartValues: transformedNewProductObject };

            }

            const { startExtractStartValues, extractStartValuesFromExisting } = transformUniqueValuesObjectFromImported(importedProduct)
            state.preSelectedVariantsProperties[itemOrderId] = extractStartValuesFromExisting
            state.productsObject[itemOrderId] = importedProduct
            const { order_id, order_number } = state.record
            const payloadData = {
                apply_to_all: false,
                order_id: order_id,
                product_id: itemOrderId,
                order_number: order_number?.toString() || '1023',
                replacedWith: {
                    productData: importedProduct,
                    selectedProperties: startExtractStartValues // selected properties of this product with which the item is replaced 
                }
            };

            state.payload = state.payload.filter((prop) => prop.product_id !== itemOrderId)
            state.payload = [...state.payload, payloadData]
            // to send with api
            const aliexpress_sku_replacing = findALiExpressSkuFromVariants({ product_variants, selectedProperties: startExtractStartValues, shipsFrom: shipsFromArray[0] })
            state.skuNotFound[itemOrderId] = true
            const payloadDataForApi = {
                product_id: itemOrderId,
                existing_product: {
                    order_id: order_id,
                    aliexpress_product_sku: aliexpress_product_sku_existing,
                    aliexpress_product_id: aliexpress_product_id_existing,

                },
                replacing_product: {
                    aliexpress_product_id: aliexpress_product_id_replacing,
                    aliexpress_product_sku: aliexpress_sku_replacing,
                    apply_to_all: false,

                },

                newShippingMethod: {
                    country: state.record.country_code,
                    // country_code: state.record.destination,
                    product_id: product_id,
                    isShippingMethod: missStockOrShipping === "DELIVERY_METHOD_NOT_EXIST" ? true : false,

                    "status": true,
                    "key": "",
                    "method_carrier": "",
                    "issue": "",
                    "cost": null,
                    "costUnit": "",
                    "shippingService": "",
                    "estimatedDeliveryTime": "",
                    "tracking": ""
                }
            }
            state.ApiPayload = state.ApiPayload.filter((prop) => prop.product_id !== itemOrderId)
            state.ApiPayload = [...state.ApiPayload, payloadDataForApi]
        },
        setValueForNewVariant: (state, action) => {
            const { value, itemOrderId, propertyName, shipsFrom } = action.payload
            let pickedProps, product;
            const updated =
                state.payload.map((record, ind) => {
                    if (record.product_id === itemOrderId) {
                        const { replacedWith } = record
                        const { selectedProperties } = replacedWith
                        // getting matched data
                        pickedProps = {
                            ...selectedProperties, [propertyName]: value
                        }
                        product = replacedWith.productData
                        return {
                            ...record,
                            replacedWith: {
                                ...replacedWith,
                                selectedProperties: {
                                    ...selectedProperties, [propertyName]: value
                                }
                            }
                        }
                    }
                    return record
                })
            state.payload = updated
            const { product_id: aliexpress_product_id_replacing, data } = product
            const { product_variants } = data
            const aliexpress_sku_replacing = findALiExpressSkuFromVariants({ product_variants, selectedProperties: pickedProps, shipsFrom })
            if (!aliexpress_sku_replacing) {
                console.log("didn't find the sku!");
                state.skuNotFound[itemOrderId] = true
                return
            }
            state.skuNotFound[itemOrderId] = false

            const updatedApiPayload =
                state.ApiPayload.map((record, ind) => {
                    if (record.product_id === itemOrderId) {
                        const { replacing_product } = record
                        return {
                            ...record,
                            replacing_product: {
                                ...replacing_product,
                                aliexpress_product_id: aliexpress_product_id_replacing,
                                aliexpress_product_sku: aliexpress_sku_replacing,
                            }
                        }
                    }
                    return record
                })
            state.ApiPayload = updatedApiPayload
        },

        changeMatchingCheck: (state, action) => {
            const { itemOrderId } = action.payload

            const updatedApiPayload =
                state.ApiPayload.map((record, ind) => {
                    if (record.product_id === itemOrderId) {
                        const { replacing_product } = record
                        const { apply_to_all } = replacing_product
                        return {
                            ...record,
                            replacing_product: {
                                ...replacing_product,
                                apply_to_all: !apply_to_all,
                            }
                        }
                    }
                    return record
                })
            state.ApiPayload = updatedApiPayload
        },
        setBlockedLowStockOrders: (state, action) => {
            state.blockedLowStockOrders = action.payload
        },

        changeShippingMethod: (state, action) => {
            const { itemOrderId, method } = action.payload
            const key = method.key
            console.log(method, ':::selected method');

            // const selectedMethod =method

            //             delete selectedMethod.key
            const updatedApiPayload =
                state.ApiPayload.map((record, ind) => {
                    if (record.product_id === itemOrderId) {
                        const { newShippingMethod } = record
                        return {
                            ...record,
                            newShippingMethod: {
                                ...newShippingMethod,
                                // key: key.toString(),
                                ...method,
                                "status": true,
                                costUnit: method?.costUnit || 'USD'

                            }

                        }
                    }
                    return record
                })
            state.ApiPayload = updatedApiPayload
        },
        replaceShippingMethodCountryChange: (state, action) => {
            const { country, country_code, itemOrderId } = action.payload
            console.log(country, country_code);
            const updatedApiPayload =
                state.ApiPayload.map((record, ind) => {
                    if (record.product_id === itemOrderId) {
                        const { newShippingMethod } = record
                        return {
                            ...record,
                            newShippingMethod: {
                                ...newShippingMethod,
                                country: country,
                                country_code: country_code
                            }

                        }
                    }
                    return record
                })
            state.ApiPayload = updatedApiPayload

        },
        variantMissingShippingGetOnlyMissingMethods: (state, action) => {
            const { itemOrderId,
                aliexpress_product_sku: aliexpress_product_sku_existing,
                aliexpress_product_id: aliexpress_product_id_existing, missStockOrShipping, product_id } = action.payload
            const { order_id, order_number } = state.record


            const payloadDataForApi = {
                product_id: itemOrderId,
                existing_product: {
                    order_id: order_id,
                    aliexpress_product_sku: aliexpress_product_sku_existing,
                    aliexpress_product_id: aliexpress_product_id_existing,

                },
                replacing_product: {
                    aliexpress_product_id: aliexpress_product_id_existing,
                    aliexpress_product_sku: aliexpress_product_sku_existing,
                    apply_to_all: false,

                },

                newShippingMethod: {
                    country: state.record.country_code,
                    product_id: product_id,
                    isShippingMethod: true,

                    "status": true,
                    "key": "",
                    "method_carrier": "",
                    "issue": "",
                    "cost": null,
                    "costUnit": "",
                    "shippingService": "",
                    "estimatedDeliveryTime": "",
                    "tracking": ""
                }
            }
            state.ApiPayload = state.ApiPayload.filter((prop) => prop.product_id !== itemOrderId)
            state.ApiPayload = [...state.ApiPayload, payloadDataForApi]
        }

    }

});

export const {setSuccessAndWarningAtReplaceVariants,setImportedShippingData,
    addNewProductToReplaceWith,
    setValueForNewVariant, setOrderRecord, setReplacedVariants,
    changeMatchingCheck,
    resetReplcaeVariantState,
    refreshOrdersTableController,
    setBlockedLowStockOrders,
    changeShippingMethod,
    replaceShippingMethodCountryChange,
    variantMissingShippingGetOnlyMissingMethods
} = replaceVariantSlice.actions;

export default replaceVariantSlice.reducer;

