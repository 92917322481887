import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../CustomLoader";
import { addNewProductToReplaceWith, setSuccessAndWarningAtReplaceVariants } from "../../../redux/slices/replace-variant-slice";
import { callBackendAPI } from "../../../helpers/apiCalls";

const DescriptionImportProductComponent = ({
    missStockOrShipping,
    itemOrderId,
    importButtonLoading,
    setImportButtonLoading,
    aliexpress_product_id,
    aliexpress_product_sku,
    product_id,
    setErrorShippingMethod,
    completeItem

}) => {
    const { success,
        formWarning } = useSelector(store => store.replaceVariant)

    // const [formWarning, setFormwarning] = useState("");
    // const [success, setSuccess] = useState(false);
    const [productLink, setProductLink] = useState('');
    const dispatch = useDispatch()

    const handleConfirm = async () => {
        setErrorShippingMethod({ err: false, message: '' })
        if (productLink) {
            // setFormwarning("");
            setImportButtonLoading(true);
            dispatch(setSuccessAndWarningAtReplaceVariants({ success: false, formWarning: false ,itemOrderId}))
            // setSuccess(false)
            //calling api
            try {
                // Make API call to backend
                const response = await callBackendAPI(
                    "aliexpress/v1_fetch_product",
                    "GET",
                    { url: productLink }
                );

                console.log(response);
                if (!response?.error && response?.status) {
                    // setSuccess(true)
                    // setFormwarning("");
                    dispatch(setSuccessAndWarningAtReplaceVariants({ success: true, formWarning: false ,itemOrderId}))

                    const { data } = response
                    // setProductData(response)
                    dispatch(addNewProductToReplaceWith({
                        importedProduct: response ? response : {},
                        itemOrderId, completeItem,
                        aliexpress_product_id,
                        aliexpress_product_sku,
                        missStockOrShipping,
                        product_id
                    }))

                } else {
                    console.log('error', response.message, formWarning);
                    // setSuccess(false)
                    // setFormwarning(response.message);
                    dispatch(setSuccessAndWarningAtReplaceVariants({ success: false, formWarning: response.message ,itemOrderId}))

                    console.log(formWarning);
                }
            } catch (error) {
                // setFormwarning(error.message);
                dispatch(setSuccessAndWarningAtReplaceVariants({ success: false, formWarning: 'Existing SKU is invalid!',itemOrderId}))
                
                console.error("Error:", error);
            } finally {
                setImportButtonLoading(false);
            }
        } else {
            // setFormwarning("Please enter url of product");
            dispatch(setSuccessAndWarningAtReplaceVariants({ success: false, formWarning: "Please enter url of product",itemOrderId}))

        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleConfirm();
        }
    };

    return (
        <div className='steps-description-container'>
            <div className='flex-container-gap'>
                <input
                    value={productLink}
                    onChange={(e) => setProductLink(e.target.value)}
                    className="form-control !h-[36px]"
                    type="text"
                    placeholder="Aliexpress link"
                    onKeyDown={handleKeyPress}
                />
                <div
                    onClick={importButtonLoading || productLink === '' ? null : handleConfirm}
                    className={` import-btn ${importButtonLoading || productLink === '' && 'cursor-not-allowed'}`}>
                    {!importButtonLoading ? (
                        "Import"
                    ) : (
                        <span className="flex justify-center items-center gap-2 px-2">
                            <p>
                                Importing
                            </p>
                            <CustomLoader color='#344054' width='20px' />
                        </span>
                    )}
                </div>
            </div>

            {success[itemOrderId] && (
                <span
                    className="add-product-modal-success"
                    style={{ color: "green", fontFamily: 'inter', fontWeight: '500' }} >
                    ✓ New product successfully imported
                </span>
            )}
            {formWarning[itemOrderId]  ? (
                <span className="add-product-modal-warning">{formWarning[itemOrderId]}</span>
            ) : (
                <span style={{ height: "28px" }}>{formWarning[itemOrderId]}</span>
            )}
        </div>
    )
}

export default DescriptionImportProductComponent