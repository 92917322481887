import {   useState } from "react";
import { Input, Segmented  } from "antd";
import VariantsImageGallery from "./VariantsImageGallery/VariantsImageGallery";
import VariantsPropertiesContainer from "./VariantsProptiesContainer/VariantsProptiesContainer";
import useVariantsData from "../../hooks/useVariantsData";
import { changeLabelNameOfProperties, setEditViewStyle,  } from "../../../redux/slices/product-variants-slice";
import { useDispatch, useSelector } from "react-redux";

const ProductVariantsComponent = (  ) => {
  const dispatch = useDispatch();
  const { productData, globallyDisabledProperties,totalEnabledVariants,editViewStyle } = useSelector((store) => store.productVariants);
  const { data } = productData;
  const { propertiesArray, uniqueValuesObject, formattedImagesObject, newImagesObject } = useVariantsData(productData);

  const [variantImages, setVariantImages] = useState(data.aliexpress_images);

  return (
    <div className="right-variant-container">
      <div className="variant-header-container">
        <h6 className="variant-header">Variants ({totalEnabledVariants})</h6>
      </div>
      <div>
        <h6 className="variant-view-text">Variant Display Style</h6>
        <Segmented
          style={{ width: "100%" }}
          className="variant-view-styles"
          defaultValue={editViewStyle}
          onChange={(value) => dispatch(setEditViewStyle(value))}
          options={["Dropdown", "Pills"]}
        />
      </div>
      {propertiesArray?.map((property, index) => (<>
       {
        uniqueValuesObject[property]?.length>1 ? 
        <div key={index}>
          <h6 className="variant-view-text">Variant : {property}</h6>

          <Input
            value={property}
            onChange={(e) =>
              dispatch(
                changeLabelNameOfProperties({
                  property,
                  value: e.target.value,
                })
              )
            }
            className={`mb-[16px] variant-name-container  `}
          />

          {newImagesObject.hasOwnProperty(property) ? (
            <VariantsImageGallery
              toIterate={uniqueValuesObject}
              property={property}
              productImages={newImagesObject[property]}
              productVariantDetails={formattedImagesObject}
              setProductImages={setVariantImages}
              aliexpressImages={variantImages}
            />
          ) : (
            <div className="grid grid-cols-3 xl:grid-cols-4 gap-2 mb-[32px]">
              {uniqueValuesObject[property]?.map((val, i) => (
                <VariantsPropertiesContainer
                  key={i}
                  property={property}
                  value={val}
                  disable={globallyDisabledProperties[property]?.includes(val) ?? false}
                />
              ))}
            </div>
          )}
        </div>:
      null
      }
      </>))}
    </div>
  );
};

export default ProductVariantsComponent;
