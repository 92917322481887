import React, { useEffect, useState } from "react";
import trashbin from "../../../../assets/trashbin.svg";
import editpencil from "../../../../assets/editpencil.svg";
import cameraIcon from "../../../../assets/cameraIcon.svg";
import ImagesGalleryModal from "../ImagesGalleryModal";

const GalleryEditImageContainer = ({
  productImages,
  setProductImages,
  aliexpressImages,
}) => {
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const [editImageIndex, setEditImageIndex] = useState();
  const [multipleFlag, setMultipleFlag] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered index

  useEffect(() => {}, [productImages]);
  const handleImageEdit = (index) => {
    setEditImageIndex(index);
    setIsImagesModalVisible(true);
  };

  const handleImageDelete = (indexToRemove) => {
    setProductImages((prevArray) =>
      prevArray.filter((item, index) => index !== indexToRemove)
    );
  };
  const handleAddMediaClick = () => {
    setMultipleFlag(true);
    setIsImagesModalVisible(true);
  };
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="gallery-edit-images-main-box">
      {productImages?.map((image, index) => (
        <div
          key={index}
          style={{ backgroundImage: `url(${image})` }}
          className="gallery-image-edit-container"
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {hoveredIndex === index && (
            <div className="background-gallery-image-overlay-edit-image-section">
              <div className="edit-image-upper-portion">
                <button
                  className="gallery-image-edit-button"
                  onClick={() => handleImageEdit(index)}
                >
                  <img src={editpencil} alt="edit" />
                </button>
              </div>
              <div className="edit-image-lower-portion">
                <button
                  className="gallery-image-delete-button"
                  onClick={() => handleImageDelete(index)}
                  danger
                >
                  <img src={trashbin} alt="delete" />
                </button>
              </div>
            </div>
          )}
        </div>
      ))}

      <div
        onClick={handleAddMediaClick}
        className="add-image-gallery-container"
      >
        <img src={cameraIcon} alt="Camera" />
        <p>Add media</p>
      </div>
      <ImagesGalleryModal
        aliexpressImages={aliexpressImages}
        multipleFlag={multipleFlag}
        openModal={isImagesModalVisible}
        productImages={productImages}
        setProductImages={setProductImages}
        setIsImagesModalVisible={setIsImagesModalVisible}
        imageindex={editImageIndex}
        setMultipleFlag={setMultipleFlag}
        productImageFlag={true}
      />
    </div>
  );
};

export default GalleryEditImageContainer;
