import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";

const ProductFooter = ({
  text,
  heading,
  productFooterSection,
  handleSectionEditChange,
  section6Image,
  image,
}) => {
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading]);
  const sectionText = DOMPurify.sanitize(content?.text);
  const handleSectionImageClick = () => {
    handleSectionEditChange(["section6Image"]);
  };
  return (
    <div className="product-footer-main-continer">
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`product-footer-img-container ${
          section6Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          <img
            className="product-footer-image"
            src={image}
            alt="Guarantee Image"
          />
        )}
      </div>
      <div
        onClick={() =>
          handleSectionEditChange([
            "satisfactionGuaranteeHeadline",
            "satisfactionGuaranteeText",
          ])
        }
        style={{ cursor: "pointer" }}
        className={`product-footer-content-container ${
          productFooterSection ? "outline-border-selected" : "outline-border"
        }`}
      >
        {content?.heading ? <h3>{content?.heading}</h3> : ""}
        <div
          className="edit-formatted-line"
          dangerouslySetInnerHTML={{ __html: sectionText }}
        ></div>
      </div>
    </div>
  );
};

export default ProductFooter;
