import { createSlice } from "@reduxjs/toolkit";




const authUserSlice = createSlice({
    name: "authUserSlice",

    initialState: {
        shopName: undefined,
        shopOwner: undefined,
        email:undefined
    },
    reducers: {
        setStoreInformation: (state, action) => {
            const { shop_owner, email } = action.payload
            state.shopOwner = shop_owner==="undefined"? undefined : shop_owner;
            state.email = email==="undefined"? undefined : email
        },

    }

});

export const {
    setStoreInformation,

} = authUserSlice.actions;

export default authUserSlice.reducer;

