
import { Collapse, Divider, message, Radio, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import plusIcon from '../../assets/plus-icon.svg'
import deleteIcon from '../../assets/delete-icon.svg'

import './ShippingConfigurationDetails.css'
import { countries, currencyUnit } from '../../constantData/variantsData';
import { useDispatch, useSelector } from 'react-redux';
import { addAllMethodsAvailable, addCountry, addMethod, addNewCountry, deleteCountry } from '../../redux/slices/shipping-config-slice';
import { callBackendAPI } from '../../helpers/apiCalls';
const { Option } = Select;

const ShippingConfigurationDetails = ({ shipsFrom }) => {
    const dispatch = useDispatch()
    const { countryMethod } = useSelector(store => store.shippingConfiguration)
    useEffect(() => {

        const selectIcon = `
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
           <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
       
       `;
        document.querySelectorAll('.ant-collapse-arrow').forEach(svg => {
            if (svg) {
                svg.outerHTML = selectIcon;
            }
        });

    }, [])
    return (
        <div className="variant-pricing-table-main-container">
            <div className="flex justify-between items-center">

                <h6 className="variant-pricing-heading">Shipping methods for countries</h6>
                {/* <button
                    type="button"
                    className="top-bar-main-container-white-button"
                >
                    Save as template
                </button> */}
            </div>
            <p className="variant-pricing-para">Select and configure the method of shipment for each country where you ship to</p>
            {
                countryMethod.map((methods, index) => {
                    const { method, country, issue, id } = methods;
                    let matchKey, methodName

                    method?.map(m => {
                        if (m.status) {
                            matchKey = m.key
                            methodName = m.shippingService
                            return
                        }
                    })

                    return (
                        <Collapse
                            key={index}
                            className='collapse-styles mb-[12px]'
                            collapsible="header"
                            defaultActiveKey={[index]}
                            items={[
                                {
                                    key: methods.id,
                                    label: <span className='flex gap-3 items-center'>
                                        <p className='country-selected-label'>
                                            {country ? countries.find(c => c.value === country)?.label : 'Country not selected'}</p>

                                        {methodName && !issue ?
                                            <p className='method-selected'>
                                                {methodName}
                                            </p>

                                            : issue ?
                                                <p className='method-issue'>Problem with the configured shipping method</p> :
                                                <p className='method-selected'>
                                                    Method not selected
                                                </p>
                                        }
                                    </span>,
                                    children: <ShippingDetailsTable
                                        key={index}
                                        id={id}
                                        method={method}
                                        country={country}
                                        matchKey={parseInt(matchKey)}
                                        shipsFrom={shipsFrom}
                                    />,
                                },
                            ]}
                        />

                    )
                })
            }



            <button onClick={() => dispatch(addNewCountry())} className='add-country-btn'>
                <span className='flex gap-2 items-center'>
                    <img className='w-4' src={plusIcon} alt="add-icon" />
                    <p className='add-country-text'>Add new country</p>
                </span>
            </button>
        </div>
    )
}
export default ShippingConfigurationDetails


const ShippingDetailsTable = ({ id, method, country, matchKey: keyToSelect, shipsFrom
}) => {
    const { productData } = useSelector(store => store.productVariants)

    const dispatch = useDispatch()
    const { countryMethod } = useSelector(store => store.shippingConfiguration)
    const [tableData, setTableData] = useState([])
    const [matchKey, setMatchKey] = useState()
    const [rowSelect, setRowSelected] = useState()
    const [loading, setLoading] = useState(false)
    useEffect(() => {

        setMatchKey(keyToSelect)
        setRowSelected({
            ...rowSelect, selectedRowKeys: [keyToSelect],
        })
    }, [keyToSelect])

    useEffect(() => {
        if (method) {

            setTableData(method)
        }

    }, [method])
    useEffect(() => {
        tableData?.map(m => {
            if (m.status) {
                setMatchKey(m.key)
                return
            }
        })

    }, [tableData])


    useEffect(() => {
        const element = document.querySelectorAll('.ant-table-selection-column')
        element.forEach(row => {
            if (!row.innerHTML) {
                row.innerText = 'Select'
            }
        });
        const selectIcon = `
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
           <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
       
       `;

        document.querySelectorAll('.ant-select-suffix').forEach(svg => {
            if (svg) {

                // svg.outerHTML = selectIcon;
            }
        });
        setTimeout(() => {

            document.querySelectorAll('.ant-table-row').forEach(row => {
                row.classList.add('row-white');
            });
        }, 200);

    }, [])

    const columns = [


        {
            title: 'Shipping Service',
            dataIndex: 'shippingService',
            width: '70%',

        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            width: '10%',
            render: (text, record) => <span className='flex gap-2'>
                <p>
                    {currencyUnit[record?.costUnit]}
                </p>
                {text}
            </span>,


        },
        {
            title: 'Estimated deliver time',
            dataIndex: 'estimatedDeliveryTime',
            width: '10%',

        },
        {
            title: 'Tracking',
            dataIndex: 'tracking',
            width: '10%',

        },
    ];



    const [selectionType, setSelectionType] = useState('radio');

    const rowSelection = {
        selectedRowKeys: [matchKey],
        onChange: (selectedRowKeys, selectedRows) => {
            const updated = tableData.map((d, i) => {
                if (parseInt(d.key) === selectedRowKeys[0]) {
                    return {
                        ...d, status: true
                    }
                }
                return {
                    ...d, status: false
                }
            })
             setTableData(updated)
            dispatch(addMethod({ selectedRows: selectedRows, id }))
        },

    };
    const handlCountrySelect = (value) => {
        setLoading(true)
        fetchShippingInfo(value)
        dispatch(addCountry({ value, id }))
    }


    // shipping method fetcher
    const fetchShippingInfo = async (value) => {
        let sku_id, currency_code, store_country_code;
         const { data } = productData;
        const { aliexpress_response, productData: internalData } = data;
        const { aliexpress_product_id } = internalData
        const { ae_store_info, ae_item_sku_info_dtos } = aliexpress_response
        const { store_country_code: storeCountryCode } = ae_store_info
        const { ae_item_sku_info_d_t_o } = ae_item_sku_info_dtos

// if it has ships from property 
        if (shipsFrom) {

            for (const aliExpressVariant of ae_item_sku_info_d_t_o) {
                const { ae_sku_property_dtos, currency_code: currencyCodeShipsFrom, sku_id: skuIdOfShipsFrom } = aliExpressVariant;
                const { ae_sku_property_d_t_o } = ae_sku_property_dtos;
                const countryToMap = shipsFrom.toLowerCase() === 'united states' ? 'united states of america' : shipsFrom;
                const country = countries.find(f => f.label.toLocaleLowerCase() === countryToMap.toLocaleLowerCase());
            
                store_country_code = country?.value;
            
                if (ae_sku_property_d_t_o.find(f => f.sku_property_name === 'Ships From' && f.sku_property_value === shipsFrom)) {
                    sku_id = skuIdOfShipsFrom;
                    currency_code = currencyCodeShipsFrom;
                    break;  
                }
            }
 
        }
        else {
            const { currency_code: currencyCodeShipsFrom, sku_id: skuIdOfShipsFrom } = ae_item_sku_info_d_t_o[0]
            sku_id = skuIdOfShipsFrom
            currency_code = currencyCodeShipsFrom
            store_country_code = storeCountryCode
        }

        try {
            const response = await callBackendAPI("shipping/get-shippping-info", 'GET',
                {
                    country_from: store_country_code,
                    id: aliexpress_product_id,
                    currency_code: currency_code,
                    sku_id: sku_id,
                    country_to: value
                }
            );
            if (response?.status) {

                const { data } = response
                const makeTableData = data.map((d, ind) => {
                    const {
                        available_stock,
                        code,
                        company,
                        delivery_date_desc,
                        free_shipping,
                        guaranteed_delivery_days,
                        max_delivery_days,
                        mayHavePFS,
                        min_delivery_days,
                        ship_from_country,
                        shipping_fee_cent,
                        shipping_fee_currency,
                        shipping_fee_format,
                        tracking
                    } = d
                    
                    return (
                        {
                            status: false,
                            key: ind + 1,
                            cost: free_shipping ? 0 : parseFloat(shipping_fee_cent),
                            costUnit: shipping_fee_currency ? shipping_fee_currency : 'USD',
                            shippingService: company,
                            estimatedDeliveryTime: min_delivery_days === max_delivery_days ? ` ${max_delivery_days} days` :
                                `${min_delivery_days}-${max_delivery_days} days`,

                            tracking: tracking ? 'Available' : 'Not Available',
                        }
                    )
                })

                setTableData(makeTableData)
                dispatch(addAllMethodsAvailable({ id, methods: makeTableData }))

            } else {
                message.error('There are no shipping methods for the selected location!', 3)
                setTableData([])

                console.log('error');
            }
        } catch (error) {
            message.error('Api access frequency exceeds the limit. this ban will last 1 seconds', 3)
            console.error("Error:", error);
        } finally {
            setLoading(false)
        }

    }


    return (

        <div>
            <p className='country-text'>Country</p>
            <div className='flex w-full h-[42px] mb-[16px] items-center gap-5'>

                <Select
                    showSearch
                    value={country === '' ? undefined : country}
                    placeholder={'Select Country'}
                    className="  !w-[160px]  !h-[42px]"
                    onChange={handlCountrySelect}
                    style={{ minWidth: '25%' }}

                    optionFilterProp="label"
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={countries.map(option => ({
                        ...option,
                        disabled: countryMethod.find(v => v.country === option.value),
                    }))}
                />

                <span onClick={() => dispatch(deleteCountry({ id }))}
                    className='trash-container'>
                    <img src={deleteIcon} alt="trash" />
                </span>
            </div>

 
            <Table
                loading={loading}
                pagination={false}
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                columns={columns}
                dataSource={tableData}
            />
        </div>
    );
};
 