import React from "react";
import "./ManageOrders.css";
import Wrapper from "../Dashboard/Wrapper";
import OrdersTable from "./OrdersTable";
const ManageOrders = () => {
  return <Wrapper>
    <div className="orders-top-header">
        <h3>Manage Orders</h3>
        <OrdersTable/>
        </div>
  </Wrapper>;
};

export default ManageOrders;
