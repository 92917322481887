import React, { useEffect, useState } from "react";
import "./ProductEditView.css";
import TopBar from "./TopBar";
import RightSection from "./RightSection";
import PrductGallery from "./PrductGallery";
import PriceAndHeadingSection from "./PriceAndHeadingSection";
import FirstContentBox from "./FirstContentBox";
import SecondContentBox from "./SecondContentBox";
import ThirdContentBox from "./ThirdContentBox";
import FourthContentBox from "./FourthContentBox";
import FifthContentBox from "./FifthContentBox";
import ProductFooter from "./ProductFooter";
import { useParams } from "react-router-dom";
import { convertResponseJsonToObject } from "../../Utilities/ConvertInObjectForm";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import VariantPricingTable from "../VariantPricingTable";
import ShippingConfigurationDetails from "../ShippingConfigurationDetails";
import { useDispatch, useSelector } from "react-redux";

import getPriceOfUniquProperties from "../../Utilities/productVariantsHandlers/getPriceOfUniquProperties";
import useVariantsData from "../hooks/useVariantsData";
import varinatsShippedFrom from "../../Utilities/productVariantsHandlers/VariantsShippedFrom";

const ProductEditView = () => {
  const dispatch = useDispatch()

  const { productData: Data, selectedProperties, totalEnabledVariants, totalAllowedVariants, globallyDisabledProperties,enableDisable } = useSelector(store => store.productVariants)

  const { propertiesArray, shipsFromArray, uniqueValuesObject,
  } = useVariantsData(Data)

  const { data } = Data
  const { productTemplate } = data
  const { id } = useParams();
  const [productData, setProductData] = useState({});
  const [productPrice, setProductPrice] = useState({ price: 0, salePrice: 0 });
  const { product_variants } = data;
  const [propertiesLength, setPropertiesLength] = useState(product_variants?.length);
  const [showSalePrice, setShowSalePrice] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editsectionImageUrl, setEditsectionImageUrl] = useState("");
  const [sectionsImages, setSectionsImages] = useState([]);
  const [aliexpressImages, setAliexpressImages] = useState([]);
  const [variantView, setVariantViewChange] = useState('Dropdown');
  const [step, setStep] = useState('Product template');
  const [shipsFrom, setShipsFrom] = useState()
  const [groupBy, setGroupBy] = useState(0)

  const [sectionClick, setSectionClick] = useState({
    bulletBenefits: false,
    guaranteeHeadline: false,
    guaranteeText: false,
    productName: false,
    salesText1: false,
    salesText2: false,
    salesText3: false,
    salesTextHeadline1: false,
    salesTextHeadline2: false,
    salesTextHeadline3: false,
    satisfactionGuaranteeHeadline: false,
    satisfactionGuaranteeText: false,
    socialProofHeadline: false,
    socialProofText: false,
    price: false,
    section1Image: false,
    section2Image: false,
    section3Image: false,
    section4Image: false,
    section5Image: false,
    section6Image: false,
    gallery: false,
    productVariants: false,
  });

  const [selectedProperty, setSelectedProperty] = useState([])
  
  useEffect(() => {
    if (!(product_variants?.length <= 1)) {
      const filtered = varinatsShippedFrom(product_variants, shipsFrom)
      const priceFromFiltered = filtered.length > 0 ? filtered : product_variants
      const variant = getPriceOfUniquProperties(selectedProperties, Data, priceFromFiltered, shipsFrom)
      setProductPrice({ price: variant?.price, salePrice: variant?.discounted_price })
      setShowSalePrice(variant?.enableSalePrice)

      console.log("selected variant =====> ", variant)
    }
  }, [Data, selectedProperties])
  
  useEffect(() => {
    let ind = 0
    const startingSelection = propertiesArray?.map(select => {
      uniqueValuesObject[select].map((pro, i) => {
        const disable = globallyDisabledProperties[select]?.includes(pro) ?? false;
        if (!disable) {
          ind = i
          return
        }
      })
      return ({ [select]: uniqueValuesObject[select][ind] })
    })

    const startSelectionObject = startingSelection.reduce((acc, item) => {
      Object.entries(item).forEach(([key, value]) => {
        acc[key] = value;
      });

      return acc;
    }, {});

    setSelectedProperty(startSelectionObject)

  },[enableDisable])

  useEffect(() => {
   


    if (product_variants?.length <= 1) {
      setShowSalePrice(product_variants[0]?.enableSalePrice)
      setProductPrice({ price: product_variants[0]?.price })
      if (product_variants[0]?.enableSalePrice) {
        setProductPrice({ price: product_variants[0]?.price, salePrice: product_variants[0]?.discounted_price })
      }

    }


  }, [])

  useEffect(() => {
    setShipsFrom(Data?.shipsFrom)
    setProductData({
      ...convertResponseJsonToObject(data.productTemplate),
      ["productStatus"]: Data.product_status,

    })
    setSectionsImages(data.sectionImages);
    setProductImages(data.aliexpress_images);
    setAliexpressImages(data.aliexpress_images)

    // }
  }, []);
  const [labelName, setLabelName] = useState(
    propertiesArray.reduce((acc, prop) => {
      acc[prop] = prop;
      return acc;
    }, {})
  )
  const handleSectionEditChange = (keys) => {
    const allFalse = Object.keys(sectionClick).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {});

    const newSectionClick = { ...allFalse };
    keys.forEach((key) => {
      newSectionClick[key] = true;
    });

    setSectionClick(newSectionClick);
  };
  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );

  return (
    <div className="product-edit-view-main-container">
      <TopBar
        shipsFrom={shipsFrom} setShipsFrom={setShipsFrom}
        propertiesLength={propertiesLength}
        showSalePrice={showSalePrice}
        productData={productData}
        productPrice={productPrice}
        productImages={productImages}
        sectionsImages={sectionsImages}
        id={id}
        step={step} setStep={setStep}
        labelName={labelName} setLabelName={setLabelName}
      />

      {
        step === 'Product template' ?
          (

            <div className="edit-product-content-container">
              <div className="product-main-left-div">
                {totalEnabledVariants > totalAllowedVariants ? <Alert message={`Enable Variants (${totalEnabledVariants}) exceed the allowed limit (${totalAllowedVariants})`} type="error" className={"variants-count-error-message"} /> : ""}
                <div className="product-main-left-container">

                  <div className="edit-product-first-section">
                    <PrductGallery
                      images={productImages}
                      galleryImages={sectionClick?.gallery}
                      handleSectionEditChange={handleSectionEditChange}
                    />
                    <PriceAndHeadingSection
                      propertiesLength={propertiesLength}
                      labelName={labelName}
                      setLabelName={setLabelName}
                      variantView={variantView}
                      productName={productData?.productName}
                      productNameSection={sectionClick?.productName}
                      showSalePrice={showSalePrice}
                      setShowSalePrice={setShowSalePrice}
                      productPrice={productPrice}
                      productPriceSection={sectionClick?.price}
                      guaranteeHeading={productData?.guaranteeHeadline}
                      guaranteeSection={sectionClick?.guaranteeHeadline}
                      guaranteeText={productData?.guaranteeText}
                      handleSectionEditChange={handleSectionEditChange}
                      productVariants={sectionClick?.productVariants}
                      selectedProperty={selectedProperty} setSelectedProperty={setSelectedProperty}
                    />

                  </div>

                  <div className="product-middle-main-container">
                    <FirstContentBox
                      heading={productData?.salesTextHeadline1}
                      text={productData?.salesText1}
                      image={sectionsImages?.section1Image}
                      salesTextHeadline1={sectionClick?.salesTextHeadline1}
                      handleSectionEditChange={handleSectionEditChange}
                      editsectionImageUrl={editsectionImageUrl}
                      setEditsectionImageUrl={setEditsectionImageUrl}
                      section1Image={sectionClick?.section1Image}
                    />

                    {loading && (
                      <div className="spinner-overlay">
                        <div className="spinner-container">
                          <Spin className="custom-spin" indicator={antIcon} />
                        </div>
                      </div>
                    )}

                    <SecondContentBox
                      heading={productData?.salesTextHeadline2}
                      text={productData?.salesText2}
                      image={sectionsImages?.section2Image}
                      handleSectionEditChange={handleSectionEditChange}
                      editsectionImageUrl={editsectionImageUrl}
                      setEditsectionImageUrl={setEditsectionImageUrl}
                      section2Image={sectionClick?.section2Image}
                      salesTextHeadline2={sectionClick?.salesTextHeadline2}
                    />
                    <ThirdContentBox
                      content={productTemplate?.bulletBenefits}
                      setSectionClick={setSectionClick}
                      image={sectionsImages?.section3Image}
                      handleSectionEditChange={handleSectionEditChange}
                      editsectionImageUrl={editsectionImageUrl}
                      setEditsectionImageUrl={setEditsectionImageUrl}
                      section3Image={sectionClick?.section3Image}
                      bulletBenefits={sectionClick?.bulletBenefits}
                    />
                    <FourthContentBox
                      heading={productData?.salesTextHeadline3}
                      text={productData?.salesText3}
                      image={sectionsImages?.section4Image}
                      handleSectionEditChange={handleSectionEditChange}
                      editsectionImageUrl={editsectionImageUrl}
                      setEditsectionImageUrl={setEditsectionImageUrl}
                      section4Image={sectionClick?.section4Image}
                      salesTextHeadline3={sectionClick?.salesTextHeadline3}
                    />
                    <FifthContentBox
                      heading={productData?.socialProofHeadline}
                      text={productData?.socialProofText}
                      image={sectionsImages?.section5Image}
                      handleSectionEditChange={handleSectionEditChange}
                      editsectionImageUrl={editsectionImageUrl}
                      setEditsectionImageUrl={setEditsectionImageUrl}
                      section5Image={sectionClick?.section5Image}
                      socialProofHeadline={sectionClick?.socialProofHeadline}
                    />
                  </div>
                  <ProductFooter
                    heading={productData?.satisfactionGuaranteeHeadline}
                    productFooterSection={sectionClick?.satisfactionGuaranteeHeadline}
                    text={productData?.satisfactionGuaranteeText}
                    handleSectionEditChange={handleSectionEditChange}
                    section6Image={sectionClick?.section6Image}
                    image={sectionsImages?.section6Image}
                  />
                </div>
              </div>
              <RightSection
                labelName={labelName}
                setLabelName={setLabelName}
                showSalePrice={showSalePrice}
                setShowSalePrice={setShowSalePrice}
                productPrice={productPrice}
                setProductPrice={setProductPrice}
                productData={productData}
                updateProductData={setProductData}
                sectionClick={sectionClick}
                setSectionClick={setSectionClick}
                handleSectionEditChange={handleSectionEditChange}
                editsectionImageUrl={editsectionImageUrl}
                setEditsectionImageUrl={setEditsectionImageUrl}
                sectionsImages={sectionsImages}
                setSectionsImages={setSectionsImages}
                productImages={productImages}
                setProductImages={setProductImages}
                aliexpressImages={aliexpressImages}
                // productVariantDetails={variantsDetails}
                setVariantViewChange={setVariantViewChange}
                variantView={variantView}
                productVariants={product_variants}
              />
            </div>)
          :
          step === 'Variant pricing' ? <VariantPricingTable
            shipsFrom={shipsFrom} setShipsFrom={setShipsFrom}
            groupBy={groupBy} setGroupBy={setGroupBy}

            labelName={labelName} setLabelName={setLabelName} productPrice={productPrice} /> :
            <ShippingConfigurationDetails shipsFrom={shipsFrom} />


      }

    </div>
  );
};

export default ProductEditView;
