import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const DeleteConfirmationModal = ({ OpenModal, setOpModal, id }) => {
  const [buttonsflag, setbuttonsflag] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {}, [OpenModal]);

  const handleCancel = () => {
    setOpModal(false);
  };
  const handleConfirm = async () => {
    setbuttonsflag(true);
    const endpoint = "template/delete-unsaved-product";
    const method = "POST";
    const payload = {
      payload: {
        id: parseInt(id),
      },
    };
    try {
      const response = await callBackendAPI(endpoint, method, {}, payload);
      if (response?.status) {
        setbuttonsflag(false);
        navigate("/create-product");
      }
    } catch (error) {
      setbuttonsflag(false);
      message.error(`Cannot cancel due to ${error.message}`, 2);
    }
  };

  return (
    <div>
      <Modal
        closable={false}
        width={500}
        open={OpenModal}
        onCancel={handleCancel}
        onOk={handleConfirm}
        okText={"Confirm"}
        cancelText="Cancel"
        okButtonProps={{
          className: buttonsflag
            ? "modal-import-button modal-import-button-disabled"
            : "modal-import-button",
          disabled: buttonsflag,
        }}
        cancelButtonProps={{
          className: buttonsflag
            ? "product-modal-cancel-button-disabled"
            : "product-modal-cancel-button",
          disabled: buttonsflag,
        }}
      >
        <div className="delete-prduct-content-container">
          <ExclamationCircleOutlined className="delete-prduct-content-container-img" />
          <h4>Are you sure?</h4>
          <p>You would not be able to revert this.</p>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;
