import React, { useEffect, useState } from 'react';
import { Checkbox, message, Select, Steps } from 'antd';
import CustomLoader from '../../CustomLoader';
import { callBackendAPI } from '../../../helpers/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { changeMatchingCheck, refreshOrdersTableController, setReplacedVariants } from '../../../redux/slices/replace-variant-slice';
import useVariantsData from '../../hooks/useVariantsData';
import DescriptionImportProductComponent from './DescriptionImportProductComponent';
import VariantDetailsComponent from './VariantDetailsComponent';
import ReplaceVariantsModal from './ReplaceVariantsModal/ReplaceVariantsModal';
import MissingShippingMethod from '../MissingShippingMethod/MissingShippingMethod';
import { countries } from '../../../constantData/variantsData';

const { Option } = Select
const ReplacementStepsForm = ({ open, itemOrderId, completeItem, missStockOrShipping, refreshTable }) => {
    const missingShipping = missStockOrShipping === 'DELIVERY_METHOD_NOT_EXIST' ? true : false
    const {
        aliexpress_product_id,
        aliexpress_product_sku
    } = completeItem?.aliexpress_details

    // const { blocked_details } = completeItem

    const dispatch = useDispatch()
    const { productsObject, payload, ApiPayload, record, blockedLowStockOrders, skuNotFound,invalidProduct, preSelectedVariantsProperties, matchedProperties } = useSelector(store => store.replaceVariant)

    const [importButtonLoading, setImportButtonLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [replaceButtonLoading, setReplaceButtonLoading] = useState(false)
    const [matchingVariants, setMatchingVarints] = useState([])
    // const [productData, setProductData] = useState()
    const [shippingData, setShippingData] = useState([])
    const [country, setCountry] = useState(record.country_code || '')
    const [changeCountry, setChangedCountry] = useState(false)

    const [shippingLoading, setShippingLoading] = useState(false)
    const [methodFetched, setMethodFetched] = useState(false)
    const [selectedShippingMethod, setSelectedShippingMethod] = useState(undefined)
    const [errorShippingMethod, setErrorShippingMethod] = useState({ err: false, message: '' })
    const [payloadForFetchShipping, setPayloadForFetchShipping] = useState({
        store_country_code: "",
        aliexpress_product_id: "",
        currency_code: "",
        sku_id: ""
    })


    const [checked, setChecked] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    //custom  hook
    const { propertiesArray, shipsFromArray,
        uniqueValuesObject,
    } = useVariantsData(productsObject[itemOrderId])

    const [shipsFrom, setShipsFrom] = useState(0)
    const productData = payload.find(d => d.product_id === itemOrderId)?.replacedWith.productData || undefined

    useEffect(() => {
        if (productData) {
            const { data } = productData;
            const { aliexpress_response, productData: internalData } = data;
            const { aliexpress_product_id } = internalData
            const { ae_store_info, ae_item_sku_info_dtos } = aliexpress_response
            const { store_country_code } = ae_store_info
            const { ae_item_sku_info_d_t_o } = ae_item_sku_info_dtos
            const { currency_code, sku_id } = ae_item_sku_info_d_t_o[0]
            setPayloadForFetchShipping({
                aliexpress_product_id, store_country_code, currency_code, sku_id
            })
        }
    }, [productData])

    useEffect(() => {

        return () => {
            setErrorShippingMethod({ err: false, message: '' })
        }
    }, [])
    useEffect(() => {

        const selectIcon = `
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
           <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
       `;
        document.querySelectorAll('.ant-select-suffix').forEach(svg => {
            if (svg) {

                svg.outerHTML = selectIcon;
            }
        });;


    }, [open])

    const formateDate = (dateStr) => {
        const date = new Date(dateStr);

        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }

    // function to get the matching variants in all blocked orders (DO NOT DELETE)
    const getMatchingVariantsForModal = () => {
        const { aliexpress_details } = completeItem
        const { aliexpress_product_sku, aliexpress_product_id } = aliexpress_details

        const formattedDate = formateDate(record.created_at)

        const matchingItems = [{
            key: '0',
            ...completeItem,
            order_id: record.order_id,
            order_number: record.order_number,
            orderDate: formattedDate,
            total: record.total,
        }]
        blockedLowStockOrders.forEach((item, index) => {
            const { items,
                order_id,
                order_number,
                orderDate,
                total,
            } = item
            const found = items.find((f, i) => {
                const { aliexpress_product_sku: findingProductSku, aliexpress_product_id: findingProductId } = f.aliexpress_details
                return ((findingProductSku === aliexpress_product_sku &&
                    aliexpress_product_id === findingProductId)
                    && item.order_id !== record.order_id)
            })
            if (found) {
                // format date
                const formattedDate = formateDate(orderDate)
                matchingItems.push({
                    key: index + 1,
                    ...found,
                    order_id,
                    order_number,
                    orderDate: formattedDate,
                    total,
                })
            }
        })
        return matchingItems
    }

    const onChange = () => {

        //***********uncomment to use the replace modal**************

        // const matchingItems = getMatchingVariantsForModal() 
        // setMatchingVarints(matchingItems)
        // if (matchingItems.length > 1) {
        //     setModalOpen(true)
        // }
        //***********uncomment to use the replace modal**************


        // matching variants are there 
        dispatch(changeMatchingCheck({ itemOrderId }))
        setChecked(!checked);
    };
    // handle steps change
    const stepsChange = (value) => {
        setCurrent(value);
    }
    // handler for replacing variant
    const handleReplaceVariant = async () => {
        setReplaceButtonLoading(true)
        try {
            // payload data for the api
            const payloadData = ApiPayload?.find(d => d?.product_id === itemOrderId)
            // Make API call to backend
            const response = await callBackendAPI(
                "orders/replace-variant",
                "POST",
                {},
                {
                    "payload": payloadData
                }
            );
            if (response?.status) {
                message.success(response.message, 3)
                dispatch(setReplacedVariants({ itemOrderId, recordId: record?.order_id }))
                refreshTable()
                // dispatch(refreshOrdersTableController())
            } else {
                message.error(response.message, 3)
            }
        } catch (error) {
            return message.error(error?.message ? error?.message : 'Api Failed!', 3)
        } finally {
            setReplaceButtonLoading(false);
        }
    }

    // fetches the shipping methods for new product 
    const fetchShippingInfo = async ({
        store_country_code,
        aliexpress_product_id,
        currency_code,
        sku_id, }) => {

        let country_from_to_pass, currency_code_to_pass, sku_id_to_pass

        if (shipsFromArray.length > 0) {
            const { data } = productData;
            
            const {  aliexpress_response } = data;
            const { ae_item_sku_info_dtos } = aliexpress_response
            const { ae_item_sku_info_d_t_o } = ae_item_sku_info_dtos

            for (const aliExpressVariant of ae_item_sku_info_d_t_o) {
                const { ae_sku_property_dtos, currency_code: currencyCodeShipsFrom, sku_id: skuIdOfShipsFrom } = aliExpressVariant;
                const { ae_sku_property_d_t_o } = ae_sku_property_dtos;
                const countryToMap = shipsFrom.toLowerCase() === 'united states' ? 'united states of america' : shipsFrom;
                const country = countries.find(f => f.label.toLocaleLowerCase() === countryToMap.toLocaleLowerCase());

                country_from_to_pass = country?.value;

                if (ae_sku_property_d_t_o.find(f => f.sku_property_name === 'Ships From' && f.sku_property_value === shipsFrom)) {
                    sku_id_to_pass = skuIdOfShipsFrom;
                    currency_code_to_pass = currencyCodeShipsFrom;
                    break;
                }
            }



        }
        else {
            country_from_to_pass = store_country_code
            currency_code_to_pass = currency_code
            sku_id_to_pass = sku_id

        }
        const value = record.country_code
        setShippingLoading(true)

        try {
            const response = await callBackendAPI("shipping/get-shippping-info", 'GET',
                {
                    country_from: country_from_to_pass,
                    id: aliexpress_product_id,
                    currency_code: currency_code_to_pass,
                    sku_id: sku_id_to_pass,
                    country_to: value

                }
            );
            if (response?.status) {

                const { data } = response
                const makeTableData = data.map((d, ind) => {
                    const {
                        available_stock,
                        code,
                        company,
                        delivery_date_desc,
                        free_shipping,
                        guaranteed_delivery_days,
                        max_delivery_days,
                        mayHavePFS,
                        min_delivery_days,
                        ship_from_country,
                        shipping_fee_cent,
                        shipping_fee_currency,
                        shipping_fee_format,
                        tracking
                    } = d
                    return (
                        {
                            status: false,
                            key: ind.toString(),
                            // carrier: 'UPS',
                            cost: free_shipping ? 0 : parseFloat(shipping_fee_cent),
                            costUnit: !shipping_fee_currency || shipping_fee_currency !== '' || shipping_fee_currency !== null ? shipping_fee_currency : '$',
                            shippingService: company,
                            estimatedDeliveryTime: min_delivery_days === max_delivery_days ? ` ${max_delivery_days} days` :
                                `${min_delivery_days}-${max_delivery_days} days`,
                            tracking: tracking ? 'Available' : 'Not Available',
                        }
                    )
                })
                setShippingData(makeTableData)
                setMethodFetched(true)
            } else {
                // message.error('', 3)
                setShippingData([])

                setErrorShippingMethod({ err: true, message: 'The imported product does not ship to the destination country, import another replacement product!' })
                console.log('error');
            }
        } catch (error) {
            message.error('Api access frequency exceeds the limit. this ban will last 1 seconds', 3)
            console.error("Error:", error);
        } finally {
            setShippingLoading(false)
        }
    }

    const ShippingMethodFetchButton = () => {
        return (
            <div className='pl-[34px] '>
                <div className='steps-description-container'>
                    <button
                        disabled={payload.find(d => d.product_id === itemOrderId) === undefined ||
                            replaceButtonLoading || importButtonLoading || skuNotFound[itemOrderId] || errorShippingMethod.err
                            || !matchedProperties[itemOrderId]?.match
                        }
                        onClick={() => {
                            setChangedCountry(false)
                            fetchShippingInfo({ ...payloadForFetchShipping })
                        }}
                        className={`replace-variant-btn-shipping  
                                disabled:cursor-not-allowed 
                                disabled:text-[#cad1d7] 
                                disabled:bg-[#f4f6f7] 
                                disabled:border-[#e3e6ea]`}
                    >
                        {
                            !shippingLoading ?
                                (
                                    "Select shipping method ->"
                                ) :
                                (
                                    <span className="flex justify-center items-center gap-2">
                                        <p>
                                            Select shipping method
                                        </p>
                                        <CustomLoader color='#656970' width='20px' />
                                    </span>
                                )
                        }
                    </button>


                    < p className='error-message-shipping'>
                        {
                            errorShippingMethod.err && errorShippingMethod.message
                        }
                        {
                          invalidProduct[itemOrderId] ? 'Invalid product sku!':  !matchedProperties[itemOrderId]?.match ? matchedProperties[itemOrderId]?.message : skuNotFound[itemOrderId] ? "Sku not found please select another variant!" : ''
                        }

                    </p>

                </div>
            </div>
        )
    }
    return (
        <>
            {/* { */}
            <MissingShippingMethod
                completeItem={completeItem}
                record={record}
                missingShipping={missingShipping}
                fetchShippingInfo={fetchShippingInfo} country={country}
                changeCountry={changeCountry} setChangedCountry={setChangedCountry}
                setCountry={setCountry} shippingLoading={shippingLoading} itemOrderId={itemOrderId}
                setSelectedShippingMethod={setSelectedShippingMethod} shippingData={shippingData} >

                {missingShipping && <div className='w-full mt-[16px] mb-[50px]'>

                    <h3 className='shipping-not-avail-heading'>
                        AliExpress does not ship this product to the desired destination
                    </h3>
                    <p className='shipping-not-avail-para'>
                        Replace the product with another to fulfill the order!
                    </p>
                </div>}
                <Steps
                    current={current}
                    onChange={stepsChange}
                    direction="vertical"
                    items={[
                        {
                            title: <h3 className='steps-heading-text'>
                                Add link of replacement product
                            </h3>,
                            description: <DescriptionImportProductComponent
                                completeItem={completeItem}

                                setErrorShippingMethod={setErrorShippingMethod}
                                product_id={completeItem.product_id}
                                missStockOrShipping={missStockOrShipping}
                                aliexpress_product_id={aliexpress_product_id || null}
                                aliexpress_product_sku={aliexpress_product_sku || null} importButtonLoading={importButtonLoading}
                                setImportButtonLoading={setImportButtonLoading} itemOrderId={itemOrderId} />,
                        },
                        // if ships from is coming in the product then this will be shown
                        shipsFromArray?.length > 0 &&
                        {
                            title: <h3 className='steps-heading-text'>
                                Select ships from
                            </h3>,
                            description: <div className='px-[10px]'>
                                <Select
                                    placeholder="Select Ships From"
                                    value={shipsFrom}
                                    className="pagination-form-control    !w-[200px]  !h-[42px]"
                                    onChange={(e) => {
                                        setErrorShippingMethod({ err: false, message: '' })

                                        setShipsFrom(shipsFromArray[e])
                                    }}
                                    style={{ marginBottom: '14px' }}
                                    optionLabelProp="label"
                                >

                                    {
                                        shipsFromArray?.map((col, i) => <Option
                                            key={i + 1} value={i} label={` ${col} `} >
                                            <span className="flex gap-2 w-full items-center">
                                                <p>{col}</p>
                                            </span>
                                        </Option>
                                        )
                                    }
                                </Select>
                            </div>
                        }
                        ,
                        ...propertiesArray?.map(((prop, ind) => {
                            return ({
                                key: ind,
                                title: <h3 className='steps-heading-text'>
                                    Select {ind === 0 ? 'first' : ind === 1 ? 'second' : 'third'}  variant to match the ordered variant {'  ' + propertiesArray}
                                </h3>,
                                description: <VariantDetailsComponent
                                    shipsFrom={shipsFrom}
                                    preSelectedProperty={preSelectedVariantsProperties[itemOrderId][ind][0]}
                                    preSelectedValue={preSelectedVariantsProperties[itemOrderId][ind][1]}
                                    itemOrderId={itemOrderId} uniqueValuesObject={uniqueValuesObject}
                                    propertiesArray={propertiesArray} key={ind} variantProperty={prop} variantProperties={uniqueValuesObject[prop]} />,
                            })
                        })),

                    ]}
                />

                <ShippingMethodFetchButton />
            </MissingShippingMethod >
            {/* //     : <> */}

            { /*  //         <Steps
        //             current={current}
        //             onChange={stepsChange}
        //             direction="vertical"
        //             items={[
        //                 {
        //                     title: <h3 className='steps-heading-text'>
        //                         Add link of replacement product
        //                     </h3>,
        //                     description: <DescriptionImportProductComponent
        //                         setErrorShippingMethod={setErrorShippingMethod}
        //                         product_id={completeItem.product_id}
        //                         aliexpress_product_id={aliexpress_product_id || null}
        //                         aliexpress_product_sku={aliexpress_product_sku || null} importButtonLoading={importButtonLoading}
        //                         setImportButtonLoading={setImportButtonLoading} itemOrderId={itemOrderId} />,
        //                 },
        //                 ...propertiesArray?.map(((prop, ind) => {
        //                     return ({
        //                         key: ind,
        //                         title: <h3 className='steps-heading-text'>
        //                             Select {ind === 0 ? 'first' : ind === 1 ? 'second' : 'third'}  variant to match the ordered variant {'  ' + propertiesArray}
        //                         </h3>,
        //                         description: <VariantDetailsComponent
        //                             // missStockOrShipping={completeItem.blocked_details}
        //                             itemOrderId={itemOrderId} uniqueValuesObject={uniqueValuesObject}
        //                             propertiesArray={propertiesArray} key={ind} variantProperty={prop} variantProperties={uniqueValuesObject[prop]} />,
        //                     })
        //                 })),

        //             ]}
        //         />

           

        //     </>
        // }
            */}
            <div className='mt-[24px]'>
                {
                    selectedShippingMethod && !changeCountry && (<span className='flex gap-2 items-center mb-[8px] '>
                        <Checkbox checked={checked} name='checkbox' onChange={onChange}></Checkbox>
                        <label onClick={onChange} className='checkbox-text'>


                            {
                                missingShipping ? " Use this shipping service for future orders as well"
                                    : "Replace matching variant for future orders"
                            }
                        </label>
                    </span>)
                }
                <button
                    disabled={
                        // payload.find(d => d.product_id === itemOrderId) === undefined ||
                        replaceButtonLoading || importButtonLoading || !selectedShippingMethod || changeCountry || skuNotFound[itemOrderId]}

                    onClick={handleReplaceVariant}
                    className={`replace-variant-btn  
                    disabled:cursor-not-allowed 
                    disabled:text-[#fff] 
                    disabled:bg-[#e9d7fe] 
                    disabled:border-[#e9d7fe]`}
                >

                    {
                        !replaceButtonLoading ?
                            (<>

                                {
                                    missingShipping ? "Use selected product and shipping method to fulfill order"
                                        : "Use selected variant and shipping method to fulfill order"
                                }
                            </>

                            ) :
                            (
                                <span className="flex justify-center items-center gap-2">
                                    <p>
                                        Use selected product and shipping method to fulfill order
                                    </p>
                                    <CustomLoader width='20px' />
                                </span>
                            )
                    }
                </button>
            </div>


            <ReplaceVariantsModal open={modalOpen} setOpen={setModalOpen}
                matchingVariants={matchingVariants} setMatchingVarints={setMatchingVarints} />
        </>
    );
};
export default ReplacementStepsForm;

