import React, { useEffect, useState } from "react";
import trashbin from "../../../../assets/trashbin.svg";
import editpencil from "../../../../assets/editpencil.svg";
import ImagesGalleryModal from "../ImagesGalleryModal";
const SectionEditImageContainer = ({
  imageUrl,
  sectionsImages,
  setSectionsImages,
  trueKey,
  aliexpressImages,
}) => {
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const [multipleFlag, setMultipleFlag] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleImageEdit = () => {
    setIsImagesModalVisible(true);
    setMultipleFlag(false);
  };

  //   const handleImageDelete = () => {
  //     setSectionsImages({ ...sectionsImages, [trueKey]: "" });
  //   };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{ backgroundImage: `url(${imageUrl})` }}
      className="image-edit-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div className="background-image-overlay-edit-image-section">
          <div className="edit-image-upper-portion">
            <button className="image-edit-button" onClick={handleImageEdit}>
              <img src={editpencil} alt="edit" />
            </button>
          </div>
        </div>
      )}

      <ImagesGalleryModal
        multipleFlag={multipleFlag}
        trueKey={trueKey}
        imageUrl={imageUrl}
        sectionsImages={sectionsImages}
        setSectionsImages={setSectionsImages}
        openModal={isImagesModalVisible}
        setIsImagesModalVisible={setIsImagesModalVisible}
        aliexpressImages={aliexpressImages}
        setMultipleFlag={setMultipleFlag}
      />
    </div>
  );
};

export default SectionEditImageContainer;
