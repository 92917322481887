

import errConnectedIcon from "../../assets/not-connected.svg";


const NotConnectedShopifyCard = () => {
    return (
        <div className="card mt-5 " style={{ minHeight: "20rem" }}>

            <img
                className="w-[90px] h-[90px]"
                src={errConnectedIcon}
                alt="success"
            />
            <span className="text-[20px] font-semibold text-[#474747]">
                Store Not Connected!{" "}
            </span>

        </div>
    )
}
export default NotConnectedShopifyCard
