import { LoadingOutlined } from "@ant-design/icons";
import { setProductDataInState, resetProductDataInState, setEditViewStyle } from "../../redux/slices/product-variants-slice";
import { useEffect, useState } from "react";
import { callBackendAPI } from "../../helpers/apiCalls";
import { useDispatch } from "react-redux";
import { message, Spin } from "antd";
import ProductEditView from "./ProductEditView";
import { useNavigate, useParams } from "react-router";
import { setCountryMethod } from "../../redux/slices/shipping-config-slice";

const ProductEditViewLoading = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleEditProduct = async (id) => {
        setLoading(true);

        try {
            const response = await callBackendAPI(
                "aliexpress/v1_fetch_saved_product",
                "GET",
                {
                    shop: localStorage?.getItem("shopName"),
                    id: id,
                }
            );

            if (response?.status) {
                const { data ,} = response
                const { productTemplate,shippingConfiguration } = data
                const { product_variants } = data
                const { variantDisplayStyle } = productTemplate
                dispatch(resetProductDataInState({}))
                dispatch(setProductDataInState(response ? response : {}))
                console.log(shippingConfiguration,'shippingConfiguration');
                dispatch(setCountryMethod(shippingConfiguration? shippingConfiguration : [
            { id: 0, country: '', method: {}, issue: null }
                    
                ]))

               
                 if (variantDisplayStyle && product_variants.length > 1) {
                    const val = variantDisplayStyle === 'button' ? 'Pills' : 'Dropdown'
                    dispatch(setEditViewStyle(val))
                } else if (product_variants.length === 1) {
                    dispatch(setEditViewStyle(''))

                }
                else {
                    dispatch(setEditViewStyle('Dropdown'))

                }

                setTimeout(() => {
                    setLoading(false);
                }, 500);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setError(true)
            message.error('Something went wrong!', 4)
            navigate('/create-product')
            // setLoading(false);
            console.error("Error:", error);
        }
    }

    useEffect(() => {
        handleEditProduct(id)
    }, [])

    const antIcon = (
        <LoadingOutlined
            className="custom-spin-icon"
            style={{ fontSize: "50px" }}
            spin
        />
    );


    return (
        loading ? (
            <div className="spinner-overlay">
                <div className="spinner-container">
                    <Spin className="custom-spin" indicator={antIcon} />
                </div>
            </div>
        ) : error ? <>
            <h1>Something went Wrong!</h1>
        </> : (
            <ProductEditView />
        )
    )
}
export default ProductEditViewLoading