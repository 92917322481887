import React from "react";
import { Pagination, Select } from "antd";
import "./TablePagination.css";
import check from "../../../assets/check.svg";
const { Option } = Select;

const TablePagination = ({
  total,
  pageSize,
  currentPage,
  onPageChange,
  onPageSizeChange,
}) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handlePageSizeChange = (size) => {
    onPageSizeChange(size);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a className="next-prev-pagination-btn">&lt; Previous</a>;
    }
    if (type === "next") {
      return <a className="next-prev-pagination-btn">Next &gt;</a>;
    }
    return originalElement;
  };

  return (
    <div className="custom-pagination">
      <div className="rows-per-page">
        <span>Show rows:</span>
        <Select
          className="pagination-form-control"
          value={pageSize.toString()}
          onChange={handlePageSizeChange}
          style={{ width: '100px' }}
          optionLabelProp="label"
        >
          <Option value="10" label="10">
            <span className="flex w-full justify-between items-center">
              <p>10</p>
              {pageSize.toString() === "10" && <img src={check} alt="checked" />}
            </span>
          </Option>
          <Option value="20" label="20">
            <span className="flex w-full justify-between items-center">
              <p>20</p>
              {pageSize.toString() === "20" && <img src={check} alt="checked" />}
            </span>
          </Option>
          <Option value="50" label="50">
            <span className="flex w-full justify-between items-center">
              <p>50</p>
              {pageSize.toString() === "50" && <img src={check} alt="checked" />}
            </span>
          </Option>
        </Select>
      </div>
      <div className="pagination-controls">
        <Pagination
          total={total}
          pageSize={pageSize}
          current={currentPage}
          onChange={handlePageChange}
          showSizeChanger={false}
          itemRender={itemRender}
        />
      </div>
    </div>
  );
};

export default TablePagination;
