// import './App.css';
// import "react-quill/dist/quill.snow.css";

// import { withAuthenticator } from '@aws-amplify/ui-react'
// import Paths from './routes';
// import { useEffect } from 'react';


// function App({ signOut, user }) {


//   return (
//    <Paths/>
//   )
// }

// export default withAuthenticator(App, {
//   // socialProviders: ['apple','facebook']
// })


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Dashboard from './Components/Dashboard';
import ManageOrders from './Components/ManageOrders/ManageOrders';
import MyProducts from './Components/MyProducts/MyProducts';
import ProductEditViewLoading from './Components/ProductEditView/ProductEditViewLoadingScreen';
import ImportingLoading from './Components/ProductEditView/ImportingProductLoading';
import CopyRightPage from './Components/CopyRight/CopyRightPage';
import './App.css'; 

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/privacy" element={<CopyRightPage />} />

        {/* Protected Routes */}
        <Route path="/*" element={<AuthenticatedApp />} />
      </Routes>
    </Router>
  );
};

// Wrap only the authenticated routes with withAuthenticator
const AuthenticatedApp = withAuthenticator(() => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/create-product" element={<MyProducts />} />
      <Route path="/orders" element={<ManageOrders />} />
      <Route path="/edit-product/:id" element={<ProductEditViewLoading />} />
      <Route path="/edit-products/:id" element={<ImportingLoading />} />
    </Routes>
  );
});

export default App;
