
export default function getPriceOfProperty(property, value, product_variants,shipsFrom) {
    // color :property
    // value:brown
    
    let getVariant = {}

    product_variants.map(variant => {
        variant.variant_properties.map(prop => {
            if (prop.shopify_property_label === property && prop.shopify_property_value === value
                // ||
                //  (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)
            ) {
                getVariant = variant
                return
            }
        })
    })
    // If a matching variant is found, return its price
    if (getVariant) {
        return getVariant;
    } else {
        return null; // Or handle if no matching variant is found
    }
    //   };

}