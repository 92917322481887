import { Input, message, Select } from 'antd'
import './IncorrectOrderDetailsPage.css'
import { useEffect, useState } from 'react'
import CustomLoader from '../../CustomLoader'
import { callBackendAPI } from '../../../helpers/apiCalls'
import { useDispatch, useSelector } from 'react-redux'
import { refreshOrdersTableController } from '../../../redux/slices/replace-variant-slice'
import { countries } from '../../../constantData/variantsData'

const { Option } = Select
const IncorrectShippingDetailsPage = ({ setOpenDrawer,refreshTable }) => {
    const { record } = useSelector(store => store.replaceVariant)
    const dispatch = useDispatch()
    // destructure values from record
    const { shipping_address, email, phone, country_code } = record
    const [updateInfoLoading, setUpdateInfoLoading] = useState(false)
    const [country, setCountry] = useState(record?.country_code || '')


    const [fieldValues, setFieldValues] = useState({
        "zip": null,
        "city": null,
        "phone": null,
        "country": null,
        "country": null,
        "address1": null,
        "address2": null,
        "province": null,
        "email": null,
    })


    useEffect(() => {
        setFieldValues({
            "zip": shipping_address?.zip || null,
            "city": shipping_address?.city || null,
            "phone": phone || null,
            "country": shipping_address?.country || null,
            "country_code": shipping_address?.country_code || null,
            "address1": shipping_address?.address1 || null,
            "address2": shipping_address?.address2 || null,
            "province": shipping_address?.province || null,
            "email": email || null,
        })
    }, [record])

    function isOptional(fieldName) {
        if (fieldName === 'phone' || fieldName === 'email' || fieldName === 'address2') {
            return true
        }
        return false
    }
    const handleUpdateMissingInfo = async () => {
        for (const key in fieldValues) {
            if (!isOptional(key)) {
                if (fieldValues[key] === null || fieldValues[key].trim() === '') {

                    return message.error('Please fix all the missing details!', 3)
                }
                else if (key === 'email' && !isValidEmail(fieldValues[key])) {
                    return message.error('Incorrect email format!', 3)

                }
            }
        }
        setUpdateInfoLoading(true)

        try {
            const { order_id } = record
            // Make API call to backend
            const payload = {
                "order_id": order_id,
                "email": fieldValues?.email ,
                "shipping_address": {
                    ...fieldValues,
                    "name": shipping_address?.name || null,
                    "company": shipping_address?.company || null,
                    "latitude": shipping_address?.latitude || null,
                    "last_name": shipping_address?.last_name || null,
                    "longitude": shipping_address?.longitude || null,
                    "first_name": shipping_address?.first_name || null,
                    // "country_code": country_code || null,
                    "province_code": shipping_address?.province_code || null
                },
                "phone": fieldValues?.phone
            }
            const response = await callBackendAPI(
                "orders/update-shipping-detail",
                "POST",
                {},
                {
                    "payload": payload
                }
            );
            if (!response?.error && response?.status) {
                message.success(response.message, 3)
                setOpenDrawer(false)
                refreshTable()
            } else {
                message.error(response.message, 3)
            }
        } catch (error) {
            setUpdateInfoLoading(false);
            return message.error(error?.message ? error.message : 'Api Failed!', 3)
        } finally {
            setUpdateInfoLoading(false);
        }

    }

    const makeFields = [
        "address1",
        "address2",
        "city",
        "province",   //State/province
        "country",
        "zip",     //ZIP/postal code
        "phone", // Phone Number
    ]
    const isNumeric = (value) => {
        const numericRegex = /^\d+$/;
        return numericRegex.test(value);
    }

    const onFieldChange = (e, fieldName) => {
        const { value } = e.target;

        // ********************** phone number validatiion for only enter numbers************************
        //  if (fieldName === 'phone') {
        //     if (!(value === '' || value === null)) {

        //         if (!isNumeric(value) || value.length > 13) {
        //             return
        //         }
        //     }
        // }\
        // *********************************************************



        setFieldValues((prev) => {
            return { ...prev, [fieldName]: value }
        })
    }
    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handlCountrySelect = (e) => {
        const country = countries.find(f => f.value === e)
        setFieldValues((prev) => {
            return {
                ...prev, country_code: e,
                country: country.label
            }
        })

    }

    return (
        <div>
            {
                makeFields.map((fieldText, index) => {
                    const isOpt = isOptional(fieldText)
                    let formattedName = fieldText.replace(/_/g, ' ');
                    // Capitalize the first letter of each word
                    formattedName = formattedName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                    formattedName = formattedName === "Province" ? "State/province" :
                        formattedName === "Zip" ? "ZIP/postal code" : formattedName === "Phone" ?
                            "Phone Number" : formattedName;
                    return (
                        <div key={index} className='missing-shipping-input-conatiner '>
                            <label className="missing-shipping-label-styles" htmlFor={fieldText}>{formattedName}</label>
                            {
                                fieldText === 'country' ?
                                    <Select
                                        value={fieldValues.country_code}
                                        placeholder={'Select Country'}
                                        className={` ${(!fieldValues.country_code || fieldValues.country_code.trim() === '') && 'border rounded-[9px] !border-[#fda29b]'} 
                                        !w-[160px]  !h-[42px]`}
                                        onChange={(e) => handlCountrySelect(e)}
                                        style={{ minWidth: '100%', }}
                                        showSearch
                                        optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={countries}
                                    />

                                    :

                                    <Input id={fieldText}
                                        value={fieldValues[fieldText]}
                                        onChange={(e) => onFieldChange(e, fieldText)}
                                        className={`missing-shipping-input-styles !w-full pricing-table-input-field
                                     ${((!fieldValues[fieldText] || fieldValues[fieldText].trim() === '') &&
                                                !isOpt) && '!border-[#fda29b]'}`} type="text"
                                    />}
                            <p className="missing-shipping-input-error-styles">
                                {
                                    ((!fieldValues[fieldText] || fieldValues[fieldText].trim() === '') && !isOpt) ? ' Incorrect information' : ""
                                    // (fieldText==='email' && isValidEmail(fieldValues[fieldText])) ? 'true' : 'Email format is not correct'
                                }
                            </p>
                        </div>
                    )
                })
            }
            <div className='missing-shipping-input-conatiner '>
                <label className="missing-shipping-label-styles" htmlFor='email'>Email</label>
                <Input id='email'
                    value={fieldValues.email}
                    onChange={(e) => onFieldChange(e, 'email')}
                    className={`missing-shipping-input-styles !w-full pricing-table-input-field `} type="text" />
                <p className="missing-shipping-input-error-styles">
                    {
                        !fieldValues.email || fieldValues.email.trim() === '' ? "" : isValidEmail(fieldValues.email) ? '' : "Invalid Email format!"
                    }
                </p>

            </div>

            <button
                onClick={handleUpdateMissingInfo}
                className={`replace-variant-btn 
                disabled:cursor-not-allowed
                 disabled:text-[#f1edf9] 
                 disabled:bg-[#9e75ff] disabled:border-[#9e75ff]
                 h-[ 40px]
                 `}>

                {!updateInfoLoading ? (
                    "Update shipping details"
                ) : (
                    <span className="flex justify-center items-center gap-2">
                        <p>
                            Updating shipping details
                        </p>
                        <CustomLoader width='20px' />
                    </span>
                )}
            </button>

        </div>
    )
}
export default IncorrectShippingDetailsPage