
export default function getPriceOfUniquProperties(values, productData, filteredvariants,shipsFrom) {
    // console.log('filtered in get price of unique:', filteredvariants);

    // color :property
    // value:brown
    const { data } = productData
    const {
        product_variants: toUseVariants
    } = data
    const product_variants = filteredvariants ? filteredvariants : toUseVariants

    let getVariant = {}
    let matchedProp = []
    product_variants.map(variant => {
        variant.variant_properties.map((prop, i) => {
            if (values[prop.shopify_property_label] === prop.shopify_property_value||
                 (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                matchedProp.push(true)

            }
        })

        if (matchedProp.length === variant.variant_properties.length) {
            getVariant = variant
            matchedProp = []

            return
        }
        matchedProp = []

    })

    if (getVariant) {
        return getVariant;
    } else {
        return null; // Or handle if no matching variant is found
    }
    //   };

}