
export default function varinatsShippedFrom(product_variants, shipsFrom) {
    console.log(shipsFrom);
    
    // const propertiesArray =['color','length','size'];
      // Find the variant that matches the given property and value
      let variant = []

      product_variants?.map(item => {
        item.variant_properties.map(prop => {
            if (prop.shopify_property_value.toLowerCase() === shipsFrom?.toLowerCase()) {
                variant.push(item)
            }
        });
    });

    return variant
  }