import { Select } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setValueForNewVariant } from "../../../redux/slices/replace-variant-slice"
import check from "../../../assets/check.svg";

const { Option } = Select
const VariantDetailsComponent = ({
    missStockOrShipping,
    variantProperty,
    variantProperties,
    propertiesArray,
    itemOrderId,
    preSelectedProperty,
    preSelectedValue,
    shipsFrom
}) => {

    const { payload, } = useSelector(store => store.replaceVariant)
    const dispatch = useDispatch()
    const [value, setValue] = useState()

    useEffect(() => {
        const v = payload.find(p => p.product_id === itemOrderId)
        const { replacedWith } = v
        const { selectedProperties } = replacedWith
        setValue(selectedProperties[variantProperty])
    }, [payload])

    useEffect(() => {
        const selectIcon = `
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
           <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>`

        document.querySelectorAll('.ant-select-suffix').forEach(svg => {
            svg.outerHTML = selectIcon;
        });
    }, [])
    useEffect(()=>{
        dispatch(setValueForNewVariant({ value, itemOrderId, propertyName: variantProperty,shipsFrom }))

    },[shipsFrom])

    const handlePropertyValueSelect = (e) => {
        dispatch(setValueForNewVariant({ value: e, itemOrderId, propertyName: variantProperty,shipsFrom }))
    }

    return (
        <div className='steps-description-container'>
            <div className='variant-dropdown-container'>
                <div>

                    <p className="variant-label-text !bg-transparent">{preSelectedProperty}</p>
                    {/* <p className="variant-label-text !bg-transparent">{variantProperty}</p> */}

                    <Select
                        style={{ width: '100%', marginBottom: '14px' }}
                        className="pagination-form-control"
                        value={variantProperty}
                        optionLabelProp="label"
                    >
                        {
                            propertiesArray.map((property, ind) => {
                                return (
                                    <Option value={property} label={property}>
                                        <span className="flex w-full justify-between items-center">
                                            <p>{property}</p>
                                            {property === variantProperty && <img src={check} alt="checked" />}
                                        </span>
                                    </Option>
                                )
                            })}
                    </Select>
                </div>
                <div>
                    {/* <div className='variant-label-text-wrapper'> */}
                    {/* <span style={{}} className="variant-label-text  !justify-start ">{value}</span> */}
                    <span style={{}} className="variant-label-text  !justify-start ">{preSelectedValue}</span>
                    {/* </div> */}
                    <Select
                        style={{ width: '100%', marginBottom: '14px', marginTop: '8px' }}
                        className="pagination-form-control"
                        value={value}
                        onChange={handlePropertyValueSelect}
                        optionLabelProp="label"
                    >{

                            variantProperties.map((property, ind) => {
                                return (
                                    <Option key={ind} value={property} label={property}>
                                        <span className="flex w-full justify-between items-center">
                                            <p>{property}</p>
                                            {property === value && <img src={check} alt="checked" />}
                                        </span>
                                    </Option>
                                )
                            })

                        }
                    </Select>
                </div>
            </div>
        </div>
    )
}
export default VariantDetailsComponent