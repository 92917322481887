import { URLS } from "../URLS";
import { getCurrentAuthenticatedSession } from "../authUser";

export async function callBackendAPI(endpoint, method = "GET", params = {}, payload = null, signal = null) {
    try {
        const apiUrl = `${URLS.BACKEND_API}/${endpoint}`;
        const accessToken = await getCurrentAuthenticatedSession();

        // Construct query parameters if params is not empty
        const queryString = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        // Append query parameters to the URL if params is not empty
        const fullUrl = queryString ? `${apiUrl}?${queryString}` : apiUrl;

        // Prepare headers with authorization
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        };

        // Prepare request options
        const requestOptions = {
            method: method,
            headers: headers,
            signal: signal,
        };

        // Include payload for POST, PUT, PATCH requests
        if (payload && ["POST", "PUT", "PATCH"].includes(method)) {
            requestOptions.body = JSON.stringify(payload);
        }

        // Make the request
        const response = await fetch(fullUrl, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
}
