
export default function makeVariants(propertiesArray, uniqueValuesObject, groupBy, property) {
  // const propertiesArray =['color','length','size'];
  // const uniqueValuesObject = {color:['red','yellow' ], length:[1,2,3],size:[42,44]};
  const uniqueValuesObjectChanged = { ...uniqueValuesObject, [groupBy]: [property] };

  const result = [];

  function combine(current, index) {
    if (index === propertiesArray.length) {
      result.push(current);
      return;
    }

    const property = propertiesArray[index];
    const values = uniqueValuesObjectChanged[property];

    values.forEach(value => {
      combine({ ...current, [property]: value }, index + 1);
    });
  }

  combine({}, 0);

  return result;
}