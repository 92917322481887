import { Select, Spin, Table } from "antd"
import { useEffect, useState } from "react"
import { countries, currencyUnit } from "../../../constantData/variantsData";
import './MissingShippingMethod.css'
import CustomLoader from "../../CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { changeShippingMethod, replaceShippingMethodCountryChange, setImportedShippingData, variantMissingShippingGetOnlyMissingMethods } from "../../../redux/slices/replace-variant-slice";
const { Option } = Select
const MissingShippingMethod = ({ children, shippingData, completeItem, record,
    setSelectedShippingMethod, shippingLoading, fetchShippingInfo, missingShipping,
    changeCountry, setChangedCountry,
    itemOrderId, country, setCountry }) => {
    const { payload,data } = useSelector(store => store.replaceVariant)

    // const [data, setData] = useState([])
    const [selectedRowKey, setSelectedRowKey] = useState([])

    useEffect(() => {
        dispatch(setImportedShippingData({itemOrderId, data:shippingData}))
        // setData(shippingData)
    }, [shippingData])
    
    useEffect(() => {
        if (missingShipping) {
            const { aliexpres_response: aliexpress_response, aliexpress_product_id,aliexpress_product_sku,product_id } = completeItem;
            // const { aliexpress_product_id } = internalData
            const { ae_store_info, ae_item_sku_info_dtos } = aliexpress_response
            const { store_country_code } = ae_store_info
            const { ae_item_sku_info_d_t_o } = ae_item_sku_info_dtos
            const { currency_code, sku_id } = ae_item_sku_info_d_t_o[0]
            console.log('arguments1222:',
                store_country_code,
                aliexpress_product_id,
                currency_code,
                sku_id,
            );
            dispatch(variantMissingShippingGetOnlyMissingMethods({  itemOrderId,
                aliexpress_product_id, 
               aliexpress_product_sku , product_id }))
            fetchShippingInfo({store_country_code,
                aliexpress_product_id,
                currency_code,
                sku_id})
        }
        // detch methods if exist for that item

    }, [completeItem])

    useEffect(() => {
        const element = document.querySelectorAll('.ant-table-selection-column')
        element.forEach(row => {
            if (!row.innerHTML) {
                row.innerText = 'Select'
            }
        });
        // return () => setData([])
    }, [])
    const columns = [


        {
            title: 'Shipping Service',
            dataIndex: 'shippingService',
            // width: '70%',

        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            // width: '10%',
            render: (text, record) => <span className='flex gap-2'>
                <p>
                    {currencyUnit[record?.costUnit] || ''}
                </p>
                {text}
            </span>,


        },

        {
            title: 'Estimated deliver time',
            dataIndex: 'estimatedDeliveryTime',
            // width: '10%',

        },
        {
            title: 'Tracking',
            dataIndex: 'tracking',
            // width: '10%',

        },
    ];
    const dispatch = useDispatch()

    const rowSelection = {
        selectedRowKeys: selectedRowKey,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log('selectedRows: ', selectedRows);
            setSelectedShippingMethod(selectedRows[0])
            setSelectedRowKey(selectedRowKeys)
            dispatch(changeShippingMethod({ itemOrderId, method: selectedRows[0] }))
        },

    };

    // const handlCountrySelect = (e) => {
    //     const countryName = countries.find(c => c.value === e)
    //     console.log(countryName, e, 'countryname');

    //     setChangedCountry(true)
    //     setCountry(e)
    //     dispatch(replaceShippingMethodCountryChange({ itemOrderId, country: countryName.label, country_code: e }))
    //     console.log(e);
    // }

    return (

        <>
            {
                /* 
                <Select
                    value={country === '' ? undefined : country}
                    placeholder={'Select Country'}
                    className="  !w-[160px]  !h-[42px]"
                    onChange={(e) => handlCountrySelect(e)
                    }
                    style={{ minWidth: '25%', marginBottom: '24px' }}
                >
                    {
                        countries?.map((col, i) => <Option
                            disabled={payload.find(d => d.product_id === itemOrderId) === undefined}
                            key={i} value={col.value} label={` ${col.label} `} >
                            <span className="flex gap-2 w-full items-baseline  ">
                                <p>{col.label}</p>
                            </span>
                        </Option>
                        )
                    }
                </Select> 
                */
            }


            {
                !missingShipping && data[itemOrderId]?.length === 0 ? <>{children}</> :
                    <Table

                        loading={shippingLoading}
                        locale={{ emptyText: children }}
                        pagination={false}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={data[itemOrderId]}
                    />
            }

            {


                data[itemOrderId]?.length > 0 && <button className="table-back-btn">
                    <p className="cursor-pointer" onClick={() => {
                        setChangedCountry(false)
                        setSelectedShippingMethod(undefined)
                        setSelectedRowKey([])
                        // setData([])
                        dispatch(setImportedShippingData({itemOrderId,data:[]}))

                    }}>

                        {"<- Back to product replacement"}
                    </p>
                    {changeCountry && <p className={`${shippingLoading && 'text-[lightgrey] cursor-not-allowed'} cursor-pointer  flex justify-end`}
                        onClick={() => {
                            if (shippingLoading) {
                                return
                            }
                            fetchShippingInfo(country)
                            setSelectedShippingMethod(undefined)

                            setChangedCountry(false)
                            setSelectedRowKey([])
                        }}>

                        {
                            'Fetch Shipping Country ->'
                        }
                    </p>}



                </button>
            }
        </>
    )

}
export default MissingShippingMethod