import checkPrentRowAndKeys from "./checkParentRowAndKeys";


export default function findIfDisable(product_variants, properties, property_name, valueToCheck,groupBy, globallyDisabledProperties) {
    const { keys, parent } = checkPrentRowAndKeys(properties)
    // Example object with keys having comma-separated values





    let disable = false
    let matchedProp = []

    disable = globallyDisabledProperties[property_name]?.includes(valueToCheck) ?? false

    if (disable) {
        return disable
    }

     product_variants.map(variant => {
        variant.variant_properties.map((prop, i) => {
            if (valueToCheck.trim() === prop.shopify_property_value || keys[groupBy]===prop.shopify_property_value  ) {
                matchedProp.push(true)
            }
        })

        if (matchedProp.length === variant.variant_properties.length) {

            disable = variant.is_disabled
            matchedProp = []

            return
        }
        matchedProp = []

    })
    return disable



}