import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";

const FirstContentBox = ({
  text,
  heading,
  handleSectionEditChange,
  image,
  section1Image,
  salesTextHeadline1,
}) => {
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading, image]);
  const handleSectionImageClick = () => {
    handleSectionEditChange(["section1Image"]);
  };
  const sectionText = DOMPurify.sanitize(content?.text);
  return (
    <div className="first-content-box-main-container">
      <div
        onClick={() =>
          handleSectionEditChange(["salesTextHeadline1", "salesText1"])
        }
        style={{ cursor: "pointer" }}
        className="first-content-box-left-container"
      >
        <div
          onClick={() =>
            handleSectionEditChange(["salesTextHeadline1", "salesText1"])
          }
          style={{ cursor: "pointer" }}
          className={`first-container-left-box ${
            salesTextHeadline1 ? "outline-border-selected" : "outline-border"
          }`}
          id="salesTextHeadline1"
        >
          {content?.heading ? <h3>{content?.heading}</h3> : ""}
          {content?.text ? (
            <div className="edit-formatted-text">
              {/* {content?.text?.split("\n").map((line, index) => (
                <p key={index} className="edit-formatted-line">
                  {line}
                </p>
              ))} */}
              <div className="edit-formatted-line" dangerouslySetInnerHTML={{ __html: sectionText }}></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`first-content-box-right-container ${
          section1Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          <img
            className="first-content-box-right-container-image"
            src={image}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default FirstContentBox;
