export default function findALiExpressSkuFromVariants({ product_variants, selectedProperties ,shipsFrom}) {
    let matchedProp = [];
    let matchedSku;

    product_variants.map(variant => {

        variant.variant_properties.map((prop, i) => {
            
            if (selectedProperties[prop.shopify_property_label] === prop.shopify_property_value
                ||  (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)
            ) {
                matchedProp.push(true)

            }
        })
        if (matchedProp.length === variant.variant_properties.length) {
            matchedProp = []
            matchedSku = variant.aliexpress_sku
        }
        matchedProp = []
        return variant
    })

    return matchedSku
}
 
 