import { createSlice } from "@reduxjs/toolkit";




const publishProductSlice = createSlice({
    name: "publishProductSlice",

    initialState: {
        isPublish:false,
        currentEditingProductId: '',

        publisPayload:{},
    },
    reducers: {
        setPublishTrue: (state, action) => {
            state.isPublish = true
            state.publisPayload = action.payload
        },
        setPublishFalse: (state, action) => {
            state.isPublish = false
        },
        publisProductId: (state, action) => {
            state.currentEditingProductId = action.payload
        },

    }

});

export const {
     setPublishFalse,
     setPublishTrue,
     publisProductId,
} = publishProductSlice.actions;

export default publishProductSlice.reducer;

