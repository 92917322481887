import React, { useState, useEffect, useCallback, useRef } from "react";
import "./RightSection.css";
import { Switch } from "antd";
import SectionEditImageContainer from "./SectionEditImageContainer";
import GalleryEditImageContainer from "./GalleryEditImageContainer/GalleryEditImageContainer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ProductVariantsComponent from "../ProductVariantsSection/ProductVariantsComponent";
import { useDispatch, useSelector } from "react-redux";
import { changeProductBullets } from "../../../redux/slices/product-variants-slice";
import getAliExpressPrices from "../../../Utilities/productVariantsHandlers/getAliExpressPrices";

const convertBulletPointsToHtml = (bulletPoints) => {

  const htmlString = `<ul>${bulletPoints
    .map((point) => `<li>${point}</li>`)
    .join("")}</ul>`;

  return htmlString;
};

const parseBulletPoints = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const bulletPoints = Array.from(tempDiv.querySelectorAll("li")).map(
    (li) => li.textContent
  );
  return bulletPoints;
};

const RightSection = ({
  labelName,
  setLabelName,
  productPrice,
  setProductPrice,
  productData,
  sectionClick,
  updateProductData,
  showSalePrice,
  setShowSalePrice,
  sectionsImages,
  setSectionsImages,
  productImages,
  setProductImages,
  aliexpressImages,
  setVariantViewChange,
  variantView,
}) => {
  // const { matchedVariant } = useSelector(store => store.productVariants)
  const dispatch = useDispatch();

  const { productBulletBenefits, productData: CompleteProductData } = useSelector(
    (store) => store?.productVariants
  );
  // const { data: prodData } = productData
  // const { product_variants } = prodData;

  const [bulletPoints, setBulletPoints] = useState([]);
  const [selectImageInfo, setSelectImageInfo] = useState({});
  const [grossProfit, setGrossProfit] = useState(0);
  const quillRef = useRef(null);
  const reactQuillRef = useCallback((node) => {
    if (node !== null) {
      quillRef.current = node.getEditor();
    }
  }, []);

  useEffect(() => {
    if (productBulletBenefits) {
      setBulletPoints(parseBulletPoints(productBulletBenefits));
    }
    if (productData.price && productData.salePrice) {
      setShowSalePrice(!!productData.salePrice);
    }
  }, [productData, sectionClick]);
  useEffect(() => {
    const grossPrice = getAliExpressPrices(CompleteProductData)

    if (Object.keys(grossPrice)[0]) {
      const firstKey = Object.keys(grossPrice)[0]
      const setPrice = showSalePrice && productPrice.salePrice > 0 ? productPrice.salePrice - grossPrice[firstKey]?.price :
        productPrice.price > 0 ? productPrice.price - grossPrice[firstKey]?.price : 0

      setGrossProfit((setPrice).toFixed(2))
      console.log('grossssssssss:', grossPrice[firstKey]);



    }
  }, [productPrice]);
  useEffect(() => {
    const trueKey = Object.keys(sectionClick).find((key) => sectionClick[key]);

    if (trueKey && sectionsImages[trueKey]) {
      setSelectImageInfo({ status: true, trueKey });
    } else {
      setSelectImageInfo({});
    }
  }, [sectionClick, sectionsImages]);

  // const handleDeleteBulletPoint = (index) => {
  //   const updatedBulletPoints = bulletPoints.filter((_, i) => i !== index);
  //   setBulletPoints(updatedBulletPoints);
  //   updateBulletBenefits(updatedBulletPoints);
  // };

  const handleAddBulletPoint = () => {
    setBulletPoints([...bulletPoints, ""]);
  };

  const updateBulletBenefits = (updatedBulletPoints) => {
    const updatedBulletBenefits =
      convertBulletPointsToHtml(updatedBulletPoints);
    dispatch(
      changeProductBullets(convertBulletPointsToHtml(updatedBulletPoints))
    );

    updateProductData((prevData) => ({
      ...prevData,
      bulletBenefits: updatedBulletBenefits,
    }));
  };

  const getTrueKeys = () => {
    return Object.keys(sectionClick).filter((key) => sectionClick[key]);
  };

  const handleBulletPointChange = (index, newValue) => {
    const updatedBulletPoints = [...bulletPoints];
    updatedBulletPoints[index] = newValue;
    setBulletPoints(updatedBulletPoints);
    updateBulletBenefits(updatedBulletPoints);

    const textarea = document.getElementById(`bulletPointTextarea_${index}`);
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.scrollHeight > 500) {
        textarea.style.height = "500px";
      }
    }
  };

  const trueKeys = getTrueKeys().filter(
    (key) => key !== "productName" && key !== "bulletBenefits"
  );

  const findPair = (keys) => {
    let headlineKey = null;
    let textKey = null;
    for (const key of keys) {
      if (key.includes("Headline")) {
        headlineKey = key;
      } else {
        textKey = key;
      }
    }
    return { headlineKey, textKey };
  };

  const { headlineKey, textKey } = findPair(trueKeys);

  const handleInputChange = (key, value) => {
    updateProductData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handlePriceChange = (key, value) => {
    if (isNaN(value)) return;
    value = value.replace(/^0+(?!$)/, ''); // Remove leading zeros

    if (value === '' || parseFloat(value) < 0) {
      value = '0'; // Prevent negative and empty values
    } else if (parseFloat(value) > 99999) {
      return// Cap the value at ten thousand
    }
    if (key === "salePrice") {
      setProductPrice({ ...productPrice, salePrice: value });
    } else if (key === "price") {
      setProductPrice({ ...productPrice, price: value });
    }

  };

  const handleHideSalePrice = () => {
    if (showSalePrice) {
      setShowSalePrice(false);
      setProductPrice({ ...productPrice, enableSalePrice: false });
    } else {
      setShowSalePrice(true);
      setProductPrice({ ...productPrice, enableSalePrice: true });
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ size: ["small", false, "large"] }],
        ["bold", "italic", "underline", "strike"],
      ],
    },
  };

  const formats = ["font", "size", "bold", "italic", "underline", "strike"];
  const processContent = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    const paragraphs = tempDiv.getElementsByTagName("p");
    const spans = paragraphs[0].getElementsByTagName("span");
    const allTags = paragraphs[0].querySelectorAll("*");
    Array.from(allTags).forEach((tag) => {
      if (tag.tagName.toLowerCase() !== "span") {
        tag.removeAttribute("class");
      }
    });
    Array.from(spans).forEach((span) => {
      const spanClass = span.className;
      const spanText = span.textContent;
      span.replaceWith(spanText);
      if (!paragraphs[0].classList.contains(spanClass)) {
        if (paragraphs[0].className) {
          paragraphs[0].className += `${spanClass}`;
        } else {
          paragraphs[0].className = spanClass;
        }
      }
    });

    // return paragraphs[0].innerHTML;
    return tempDiv?.innerHTML;
  };
  const handleEditorChange = useCallback(
    (content, delta, source, editor) => {
      let hasSizeAttribute = false;
      delta.ops.forEach((op) => {
        if (op.attributes && op.attributes.size) {
          hasSizeAttribute = true;
          const finalContent = processContent(content);
          if (source === "user" && finalContent) {
            updateProductData((prevData) => ({
              ...prevData,
              [textKey]: finalContent || content,
            }));
          }
        }
      });

      if (!hasSizeAttribute && source === "user") {
        updateProductData((prevData) => ({
          ...prevData,
          [textKey]: content,
        }));
      }
    },
    [productData, textKey, updateProductData]
  );

  return (
    <div className="right-section-main-container">
      {sectionClick?.productName && (
        <>
          <div className="title-container">Title</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Header</label>
              <input
                className="form-input"
                type="text"
                value={productData?.productName}
                onChange={(e) =>
                  handleInputChange("productName", e.target.value)
                }
              />
            </div>
          </div>
        </>
      )}

      {sectionClick?.bulletBenefits && bulletPoints && (
        <>
          <div style={{ marginBottom: "20px" }} className="title-container">
            Section bullet points
          </div>
          <div
            className="input-container"
            style={{ marginTop: "60px" }}
          >
            {bulletPoints?.map((point, index) => (
              <div className="mb-[12px]" key={index}>
                <label className="input-label">Text</label>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-start",
                  }}
                >
                  <textarea
                    id={`bulletPointTextarea_${index}`}
                    className="form-input"
                    style={{
                      height: "auto",
                      resize: "none",
                      overflowY: "hidden",
                    }}
                    onPointerEnter={(e) => {
                      e.target.style.height = "auto";
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                    value={point}
                    onChange={(e) =>
                      handleBulletPointChange(index, e.target.value)
                    }
                  />
                </div>
              </div>
            )
            )}
          </div>
        </>
      )}

      {headlineKey && textKey && (
        <>
          <div className="title-container">
            {headlineKey === "guaranteeHeadline" ||
              headlineKey === "satisfactionGuaranteeHeadline"
              ? "Accordion"
              : "Section text"}
          </div>

          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">
                {headlineKey === "guaranteeHeadline" ? "Title" : "Heading"}
              </label>
              <input
                className="form-input"
                type="text"
                value={productData[headlineKey]}
                onChange={(e) => handleInputChange(headlineKey, e.target.value)}
              />
            </div>

            <div className="input-container">
              <label className="input-label">Text</label>
              <ReactQuill
                ref={reactQuillRef}
                className="text-editor-input"
                value={productData[textKey]}
                onChange={(content, delta, source, editor) =>
                  handleEditorChange(content, delta, source, editor)
                }
                placeholder="Write something..."
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </>
      )}
      {sectionClick?.price && (
        <>
          <div className="title-container">Price</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Regular price</label>
              <input
                className="form-input"
                type="number"
                value={productPrice?.price}
                onChange={(e) => handlePriceChange("price", e.target.value)}
              />
            </div>
            <div className="sale-price-input-container">
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="input-label"
              >
                Sale price
                <Switch
                  style={{ marginLeft: "5px" }}
                  checked={showSalePrice}
                  onChange={handleHideSalePrice}
                />
              </label>
              {showSalePrice && (
                <input
                  className="form-input"
                  type="number"
                  value={productPrice?.salePrice}
                  onChange={(e) =>
                    handlePriceChange("salePrice", e.target.value)
                  }
                />
              )}

            </div>
            <div className="input-container">
              <label className="input-label">Gross Profit  </label>
              {/* <input
                className="form-input"
                type="number"
                value={productPrice?.price}
                // onChange={(e) => handlePriceChange("price", e.target.value)}
              /> */}
              <div className="grossprofit-con">
                <span style={{ color: grossProfit < 0 ? 'red' : 'grey' }}>
                  $ {grossProfit === 0 || !grossProfit ? '-' : grossProfit}
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {selectImageInfo?.status && (
        <>
          <div className="title-container">Section media</div>
          <div className="input-main-container">
            <div className="input-container">
              <label className="input-label">Media</label>
              <SectionEditImageContainer
                trueKey={selectImageInfo?.trueKey}
                imageUrl={sectionsImages[selectImageInfo?.trueKey]}
                sectionsImages={sectionsImages}
                setSectionsImages={setSectionsImages}
                aliexpressImages={aliexpressImages}
              />
            </div>
          </div>
        </>
      )}

      {Object.keys(sectionClick).some(
        (key) => key.includes("gallery") && sectionClick[key]
      ) && (
          <>
            <div className="title-container">Gallery</div>
            <div className="input-main-container">
              <div className="input-container">
                <label className="input-label">Media</label>
                <GalleryEditImageContainer
                  productImages={productImages}
                  setProductImages={setProductImages}
                  aliexpressImages={aliexpressImages}
                />
              </div>
            </div>
          </>
        )}
      {sectionClick?.productVariants && (
        <ProductVariantsComponent
          labelName={labelName}
          setLabelName={setLabelName}
          variantView={variantView}
          setVariantViewChange={setVariantViewChange}
        />
      )}
    </div>
  );
};

export default RightSection;
