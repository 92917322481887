import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const SecondContentBox = ({
  text,
  heading,
  handleSectionEditChange,
  image,
  section2Image,
  editsectionImageUrl,
  setEditsectionImageUrl,
  salesTextHeadline2,
}) => {
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading, image]);
  const handleSectionImageClick = () => {
    setEditsectionImageUrl(image);
    handleSectionEditChange(["section2Image"]);
  };
  const sectionText = DOMPurify.sanitize(content?.text);

  return (
    <div className="first-content-box-main-container">
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`first-content-box-right-container ${
          section2Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          <img
            className="first-content-box-right-container-image"
            src={image}
            alt=""
          />
        )}
      </div>
      <div
        onClick={() =>
          handleSectionEditChange(["salesTextHeadline2", "salesText2"])
        }
        style={{ cursor: "pointer" }}
        className="second-content-box-left-container"
      >
        <div
          className={`second-container-left-box ${
            salesTextHeadline2 ? "outline-border-selected" : "outline-border"
          }`}
        >
          {content?.heading && <h3>{content?.heading}</h3>}
          <div
            style={{ color: "#373b42" }}
            dangerouslySetInnerHTML={{ __html: sectionText }}
          ></div>{" "}
        </div>
      </div>
    </div>
  );
};

export default SecondContentBox;
