import React, { useState } from "react";
import NoDataContainer from "../../../assets/NoDataContainer.svg";
import importIcon from "../../../assets/importIcon.svg";
import AddProductModal from "../AddProductModal";

const NoDataComponent = () => {
     const [showAddProductModal, setShowAddProductModal] = useState(false);
  return (
    <div className="no-data-component-main-container">
      <img style={{ marginBottom: "16px" }} src={NoDataContainer} alt="" />
      <h4>Start by importing a product</h4>
      <p className="mt-2">
        Any products imported from AliExpress will live here.
      </p>
      <p className="lower-start-selling-p mb-5">
        Start selling by importing a product.
      </p>
      <button className="import-product-btn" type="button" onClick={() => setShowAddProductModal(true)}>
        Import product
        <img src={importIcon} alt="icon" />
      </button>
      <AddProductModal OpenModal={showAddProductModal} setOpModal={setShowAddProductModal}/>
    </div>
  );
};

export default NoDataComponent;
