import React, { useEffect, useState, } from "react";
import { Tabs, Table, Spin, message } from "antd";
import importIcon from "../../../assets/importIcon.svg";
import "./ProductsStatusTable.css";
import NoDataComponent from "../NoDataComponent";
import AddProductModal from "../AddProductModal";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { LoadingOutlined } from "@ant-design/icons";
import TablePagination from "../../Commons/TablePagination";
import currentAuthenticatedUser from "../../../authUser";
import draftArrow from "../../../assets/draftArrow.svg";
import { useDispatch, useSelector, } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { publisProductId, setPublishFalse } from "../../../redux/slices/publish-product-slice";
import CustomLoader from "../../CustomLoader";

const ProductsStatusTable = () => {
  const { isPublish, currentEditingProductId, publisPayload } = useSelector(store => store.publishProduct)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [productCounts, setproductCounts] = useState({
    all: 0,
    draft: 0,
    active: 0,
  });
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [rowsPerPageNumber, setRowsPerPageNumber] = useState(10);
  const [tab, setTab] = useState({ key: 1, name: "all" });
  const [currentTotal, setCurrentTotal] = useState(10);
  const [loading, setLoading] = useState(true);

  const handleEditProduct = async (id) => {
    dispatch(publisProductId(id))
    navigate(`/edit-product/${id}`)
  }
  const productTabs = [
    {
      key: "1",
      label: (
        <div className="tab-label-container">
          <div>All products</div>
          <span className="products-number-container">
            {productCounts?.all}
          </span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="tab-label-container">
          <div>Active</div>
          <span className="products-number-container">
            {productCounts?.active}
          </span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="tab-label-container">
          <div>Draft</div>
          <span className="products-number-container">
            {productCounts?.draft}
          </span>
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "Product ID",
      width: "100px",
    },
    {
      title: "Product Title",
      dataIndex: "product_title",
      key: "Product Title",
      width: "528.5px",
      render: (text, record) => (
        <div className="product-column-title-container">
          <span className="product-title-image-container">
            <img src={record?.product_image} alt="" />
          </span>
          <>{text}</>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "528.5px",
      render: (status, record) =>
        record.product_id === currentEditingProductId && isPublish ?
          <span className="!flex !justify-center !items-center  !gap-2 !w-[230px] !bg-[#F4F6F7] product-active-status ">
            <CustomLoader width='20px' color='#6941C6' />
            {/*  */}
            <p className="!font-semibold !text-[--primary-color]">
              Uploading product...
            </p>
          </span>

          : status === "publish" ? (
            <a
              onClick={() => handleEditProduct(record.product_id)}
              // to={`/edit-product/${record.product_id}`}
              className="product-active-status"
            >
              Active
            </a>
          ) : (
            <a
              onClick={() => handleEditProduct(record.product_id)}

              // to={`/edit-product/${record.product_id}`}
              className="product-draft-status"
            >
              Draft
              <span className="product-draft-status-edit">
                Edit <img src={draftArrow} alt="" />
              </span>
            </a>
          ),
    },
  ];
  const onTabChange = (key) => {
    if (key === "1") {
      setTab({ ...tab, key: "1", name: "all" });
    } else if (key === "2") {
      setTab({ ...tab, key: "2", name: "publish" });
    } else if (key === "3") {
      setTab({ ...tab, key: "3", name: "draft" });
    }

    setPaginationNumber(1);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updateAble';

  useEffect(() => {
    if (!isPublish) {
      fetchProducts();
    }
    else {
      fetchProducts()
      setTimeout(() => {
        publishProduct()

      }, 3000);


      // Promise.all([
      //   fetchProducts(),
      //   publishProduct()
      // ])
    }
  }, [tab, paginationNumber, rowsPerPageNumber]);
  const alertUser = (e) => { e.preventDefault(); e.returnValue = ""; };

  // when the product is published
  const publishProduct = async () => {
    // messageApi.open({
    //   key,
    //   type: 'loading',
    //   content: 'Uploading product...',
    //   duration: 100000
    // });
    // // avoid unexpected closing the tab and browser
    // const alertUser = (e) => { e.preventDefault(); e.returnValue = ""; };
    // window.addEventListener("beforeunload", alertUser);

    try {
      const response = await callBackendAPI("template/v1_upload_images_onShopify", 'POST', {}, {
        payload: publisPayload
      });

      if (response?.status) {
        messageApi.open({
          key: 'success',
          type: 'success',
          content: 'Successfully uploaded!',
          duration: 4,
        });
      } else {
        messageApi.open({
          key: 'error',
          type: 'error',
          content: 'could not upload!',
          duration: 4,
        });
      }
    } catch (error) {
      messageApi.open({
        key: 'error',
        type: 'error',
        content: 'could not upload!',
        duration: 4,
      });
      console.error("Error:", error);
    } finally {
      window.removeEventListener("beforeunload", alertUser);
      messageApi.destroy(key)
      dispatch(setPublishFalse())
    }
  }
  //saved products fetcher method
  const fetchProducts = async () => {
    if (isPublish) {
      // messageApi.open({
      //   key,
      //   type: 'loading',
      //   content: 'Uploading product...',
      //   duration: 100000
      // });
      // avoid unexpected closing the tab and browser
      window.addEventListener("beforeunload", alertUser);

    }
    setLoading(true);
    setProducts([]);
    const {userId} = await currentAuthenticatedUser();
    try {
      const response = await callBackendAPI(
        "aliexpress/v1_get_saved_products",
        "GET",
        {
          userId: userId,
          shop: localStorage?.getItem("shopName"),
          status: tab.name,
          page: paginationNumber,
          limit: rowsPerPageNumber,
        }
      );

      if (response?.status) {
        if (tab.name === "all") {
          setCurrentTotal(response?.counts?.all);
        } else if (tab.name === "active") {
          setCurrentTotal(response?.counts?.publish);
        } else if (tab.name === "draft") {
          setCurrentTotal(response?.counts?.draft);
        }
        setproductCounts(response?.counts);
        const newProducts = response?.data?.map((product) => ({
          product_id: product?.id,
          product_title: product?.product_title,
          status: product?.status,
          product_image: product?.product_image,
        }));
        setProducts(newProducts);
        setLoading(false);
      } else {
        setLoading(false);
      }

    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );

  return (
    <div className="products-table-main-container">
      {contextHolder}
      {tab && (
        <Tabs defaultActiveKey="1" items={productTabs} onChange={onTabChange} />
      )}
      <div className="import-product-btn-row">
        <button
          className="import-product-btn"
          type="button"
          onClick={() => setShowAddProductModal(true)}
        >
          Import product
          <img src={importIcon} alt="icon" />
        </button>
      </div>

      <Spin indicator={antIcon} spinning={loading} style={{ width: "100%" }}>
        <div style={{ width: "100%", }}>
          <Table

            onRow={(record) => {
              return {
                onClick: () => {
                  if (currentEditingProductId === record.product_id && isPublish) {
                    return
                  }
                  handleEditProduct(record.product_id)
                }
              };
            }}
            pagination={false}
            style={{ width: "100%" }}
            rowClassName={(record) => (record.product_id === currentEditingProductId && isPublish ? " !cursor-not-allowed" : 'table-row')}
            columns={columns}
            dataSource={products}
            locale={{ emptyText: <NoDataComponent /> }}
            rowKey="product_id"

          />
          {currentTotal > 0 ? (
            <TablePagination
              total={currentTotal}
              pageSize={rowsPerPageNumber}
              currentPage={paginationNumber}
              onPageChange={setPaginationNumber}
              onPageSizeChange={setRowsPerPageNumber}
            />
          ) : (
            ""
          )}
        </div>
      </Spin>

      <AddProductModal
        fetchProducts={fetchProducts}
        OpenModal={showAddProductModal}
        setOpModal={setShowAddProductModal}
      />
    </div>
  );
};

export default ProductsStatusTable;
