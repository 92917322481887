import './ReplaceVariantSidePage.css'
import arrowIcon from "../../../assets/back-arrow-grey.svg";
import { Collapse, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import ReplacementStepsForm from './ReplaceVariantSidePage';
import ReplaceVariantsModal from './ReplaceVariantsModal/ReplaceVariantsModal';
import { useDispatch, useSelector } from 'react-redux';
import IncorrectShippingDetailsPage from '../IncorrectOrderDetailsPage/IncorrectOrderDetailsPage';
import { resetReplcaeVariantState } from '../../../redux/slices/replace-variant-slice';

const OrderFullfilmentSidePage = ({ openDrawer, setDrawerOpen, lineItems, incorrectShipping, refreshTable }) => {

    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const { replacedVariants, record } = useSelector(store => store.replaceVariant)
    useEffect(() => {

        const selectIcon = `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
           <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
       </svg>`;

        document.querySelectorAll('.ant-collapse-arrow').forEach(svg => {
            if (svg) {
                svg.outerHTML = selectIcon;
            }
        });
    }, [openDrawer])

    const onClose = () => {
        setDrawerOpen(false);
        dispatch(resetReplcaeVariantState())
    };

    const CollapseLabelComponent = ({ productName, description, image, replaced, missStockOrShipping }) => {
        return (
            <div className='flex justify-between items-center pr-[12px]'>
                <div className='collapse-label-container  '>
                    <span className='product-img-container  '>

                        <img style={{}} className="rounded-[8px]" src={image} alt={image} />
                    </span>
                    <span className=''>
                        <h2 className='collapse-product-name'>{productName}</h2>
                        <p className='collapse-label-description'>{description}</p>
                    </span>

                </div>
                <div className={` ${replaced ? 'replaced-low-stock' : 'out-of-stock'}`}>
                    <p >{
                        replaced && missStockOrShipping === 'DELIVERY_METHOD_NOT_EXIST' ? 'Shippping method selected' :
                            replaced && missStockOrShipping !== 'DELIVERY_METHOD_NOT_EXIST' ? "Replaced" :
                                !replaced && missStockOrShipping === 'DELIVERY_METHOD_NOT_EXIST' ? "Missing shipping method" : "Out of stock"}

                    </p>
                </div>
            </div>
        )
    }


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const purchaseDate = formatDate(record.created_at)

    const { shipping_address } = record
    return (
        <>
            <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                // open={true}
                open={openDrawer}
            >

                <div className="order-fullfillment-container">
                    <div className='mb-[40px]'>
                        <div className='flex w-full justify-between items-center'>
                            <h3 className='order-id'>
                                Order #{record?.order_number}
                            </h3>
                            <div onClick={onClose} className="button flex gap-2 items-center h-[20px]  cursor-pointer  ">
                                <img
                                    className="arrow-icon-180 "
                                    src={arrowIcon}
                                    alt="arrow pointing right"
                                />
                                <span>Back</span>
                            </div>
                        </div>
                        <p className='order-details-text'>Purchase date {purchaseDate}</p>
                        <p className='order-details-text'>Customer {shipping_address?.name}</p>
                        <p className='order-details-text'>Shipping destination {record?.destination}</p>
                    </div>
                    <div
                        className={`attention-container   w-full !rounded-[8px] mb-4 !p-[10px]`}>
                        <span className='text-wrap text-left attention-text'>
                            {incorrectShipping ?
                                "Attention required: This order could not be created on AliExpress with the current shipping details. Update the fields indicating incorrect information."
                                : `Attention required: One or more
                            product of this order is out of stock.
                            You can replace the missing variants with another product’s variant below.`
                            }
                        </span>
                    </div>
                    {
                        // return shipping page or replace variant
                        incorrectShipping ? <IncorrectShippingDetailsPage setOpenDrawer={setDrawerOpen}
                        refreshTable={refreshTable}

                        />
                            :
                            lineItems.map((colp, index) => {
                                let aliexpress_product_sku;
                                if (colp.aliexpress_details) {
                                    const {
                                        aliexpress_product_sku: sku,
                                    } = colp.aliexpress_details
                                    aliexpress_product_sku = sku
                                }
                                const replaced = replacedVariants[record.order_id]?.find(f => f === aliexpress_product_sku)

                                return (
                                    <div key={index} className={`collapse-borders ${replaced && 'p-[16px]'}`}>
                                        {
                                            replaced ?
                                                <CollapseLabelComponent
                                                    missStockOrShipping={colp.blocked_details}
                                                    replaced={true} productName={colp?.title || 'Product Name'}
                                                    description="Link to Aliexpress product"
                                                    image={colp?.image}
                                                /> :
                                                <Collapse
                                                    key={index}
                                                    bordered={false}
                                                    className='collapse-styles  '
                                                    defaultActiveKey={['0']}
                                                    items={[
                                                        {
                                                            key: index,
                                                            label: <CollapseLabelComponent replaced={false}
                                                                missStockOrShipping={colp.blocked_details}

                                                                productName={colp?.title || 'Product Name'} description="Link to Aliexpress product"
                                                                image={colp?.image}
                                                            />,

                                                            children: <ReplacementStepsForm
                                                                missStockOrShipping={colp.blocked_details}
                                                                refreshTable={refreshTable} completeItem={colp} itemOrderId={aliexpress_product_sku}
                                                                modalOpen={modalOpen} setModalOpen={setModalOpen} open={openDrawer} />
                                                            ,
                                                        },

                                                    ]}
                                                />
                                        }
                                    </div>
                                )
                            })
                    }
                    <ReplaceVariantsModal open={modalOpen} setOpen={setModalOpen} />
                </div>
            </Drawer>
        </>
    );
};
export default OrderFullfilmentSidePage